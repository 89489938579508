import React,{useState,useEffect,Fragment} from 'react';
import Modal from '../UI/Modal';
import Button from '../UI/Button';
import classes from './Confirmation.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';


const NotifyClient = (props) =>{

	const [nowLoading, setNowLoading] = useState(false);

	const handleYes = () =>{
		props.yesClick({id:props.id});
		setNowLoading(true);
	}

	const handleNo = () =>{
		props.noClick({id:props.id});
	}
	
	return(
	<Fragment>
	{!nowLoading?
		<Modal className={classes.singleModal}>
			<p>Ειδοποιήσατε τον πελάτη ότι είναι έτοιμη η παραγγελία του;</p>
			<div className={classes.buttonGrid}>
			<Button className={classes.confirmation} onClick={handleYes}>Ναι</Button>
			<Button className={classes.confirmation} onClick={handleNo}>Όχι</Button>
			</div>
		</Modal>:
		<Modal className={`${classes.singleModal} ${classes.loadingModal}`}>
		<FontAwesomeIcon className={classes.loadingIcon} icon={faCircleNotch} spin />
		</Modal>
	}
	</Fragment>
	)
}

export default NotifyClient;