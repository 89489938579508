import React,{Fragment,useState,useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faSave, faEye, faCheck} from '@fortawesome/free-solid-svg-icons'
import { Navigate, Route, Routes, useNavigate,Outlet, Link  } from "react-router-dom";
import isLoggedIn from '../UserLogin/UserLogin';
import {useCookies} from 'react-cookie';
import Input from '../UI/Input';
import classes from './SingleDeficiencies.module.css'
import axios from 'axios';
import moment from 'moment';

//view products
import FabricView from '../ViewProducts/FabricView'
import CurtainRodView from '../ViewProducts/CurtainRodView'
import PersidesView from '../ViewProducts/PersidesView'
import RollerView from '../ViewProducts/RollerView'
import XaliView from '../ViewProducts/XaliView'
import RomanView from '../ViewProducts/RomanView'
import CustomProductView from '../ViewProducts/CustomProductView'
import DiadromosView from '../ViewProducts/DiadromosView'


const SingleDeficiencies = (props) =>{
  const [ready,setReady] = useState(0);

  const navigate = useNavigate();
  const [cookies, setCookies, removeCookies] = useCookies(['user']);
  const [isLoaded, setIsLoaded] = useState(false);
  const [orderDate, setOrderDate] = useState('');
  const [viewModal, setViewModal]=useState('');
  const [productStatus, setProductStatus]=useState('');
  const [statusChange, setStatusChange]=useState("");
  const [orderedQuantity, setOrderedQuantity]=useState(0);
  const [checked, setChecked]=useState(props.checked);

  useEffect(() => {
    setIsLoaded(true);
  }, []);
  

  useEffect(() => {
    setChecked(props.checked)
  },[props.checked])

  const handleOrderedQuantityChange = (event) =>{
    setOrderedQuantity(event.target.value);
    props.onQuantityUpdate({id: props.id, ordered_quantity: event.target.value})
  }
  const handleChecked = (event) =>{
    setChecked(event.target.checked);
    let checkedValue = event.target.checked?1:0;
    let data = new FormData();
    data.append('product_id', props.id);
    data.append('checked', checkedValue);
    data.append('quantity', props.quantity);
    data.append('ordered_quantity', event.target.checked?orderedQuantity:0);
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://api.orders.e-interior.gr/api/ellipseis/product/save',
      headers: { 
        'Accept': 'application/json', 
        'Authorization': 'Bearer '+cookies.Token, 
      },
      data : data
    };

    axios.request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
      props.onCheckedUpdate({id: props.id, checked: event.target.checked});
    })
    .catch((error) => {
      console.log(error);
    });
  }
    return(

      <Fragment>
        <tr className={`${checked&&classes.checkedProduct} ${classes.productRow}`}>
          <td>{props.product}</td>
          <td>{props.code}</td>
          <td>{props.category}</td>
          <td>{props.quantity}</td>
          <td><input
            className={classes.quantityInput}
            type="number"
            value={props.ordered_quantity}
            onChange={handleOrderedQuantityChange}/></td>
          <td><input
            type="checkbox"
            onChange={handleChecked}
            checked={checked}/></td>
          <td>{checked ? `${props.updated_at ? props.updated_at.split('T')[0]:''}`:''}</td>
        </tr>

      </Fragment>)
  }
  
  

export default SingleDeficiencies;
