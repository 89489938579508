import React, {useState,useEffect} from 'react';
import Card from '../UI/Card';
import classes from './ProductList.module.css'
import { Navigate, Route, Routes, useNavigate,Outlet, Link  } from "react-router-dom";
import {useCookies} from 'react-cookie';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPencilAlt } from '@fortawesome/free-solid-svg-icons';

const ProductList = () =>{
	const navigate = useNavigate();
	const [cookies, setCookies, removeCookies] = useCookies(['user']);
	const [products,setProducts] = useState([]);
	const [isLoaded, setIsLoaded] = useState(false);
   
  useEffect(() => {
    setIsLoaded(true);
}, []);
  useEffect(() => {
  	const config = {
      headers: { 'Authorization': 'Bearer '+cookies.Token}
    };
    axios.post('https://api.orders.e-interior.gr/api/products',{
      key: cookies.Token},
      config
    )
    .then(function (response) {
      // console.log(response.data);
      setProducts(response.data);
      console.log(products);

    })
    .catch(function (error) {
      if (error.response.data.message==="Unauthenticated."){
        removeCookies('Name', { path: '/' });
        removeCookies('Token', { path: '/' });
        navigate("/user-login");
      }
      console.log(error);
    });
  },[isLoaded])

  return(
  	<Card className={classes.productsCard}>
    <table className="table table-hover">
      <thead>
        <tr>
          <th>Προϊόν</th>
          <th>Κατασκευαστής</th>
          <th>Κωδικός</th>
          <th>Παραγγελία</th>
          <th>Ενέργειες</th>
        </tr>
      </thead>
      <tbody>
    		{products.map(({product_type,manufacturer,code,order_id})=>
        <tr>
    			<td>{product_type}</td>
          <td>{manufacturer}</td>
          <td>{code}</td>
          <td>{order_id}</td>
          <td><FontAwesomeIcon icon={faPencilAlt} /></td>
        </tr>)}
      </tbody>
    </table>
  	</Card>)
}

export default ProductList;