import React,{useState,useEffect, Fragment} from 'react';
import { Navigate, Route, Routes, useNavigate,Outlet, Link  } from "react-router-dom";
import {useCookies} from 'react-cookie';
import Modal from '../UI/Modal';
import Input from '../UI/Input';
import Button from '../UI/Button';
import OrderContext from '../../store/order-context'
import classes from './Views.module.css';
import axios from 'axios';

const RollerView = (props) =>{
  //states
  const navigate = useNavigate();
  const [cookies, setCookies, removeCookies] = useCookies(['user']);
  const [isLoaded, setIsLoaded] = useState(false);
  const [productInfo, setProductInfo] = useState(null);
  const [productOptions, setProductOptions] = useState(null);

  useEffect(() => {
    setIsLoaded(true);
}, []);

  useEffect(() => {
    console.log('proped id is :' +props.id);
    const config = {
      headers: { Authorization: "Bearer 5|X4kcu2RRJQOFjOZxtMb16xzk5dM1hgSTKeAPS9Lp"}
    };
    axios.post('https://api.orders.e-interior.gr/api/product/'+props.id,{
      key: cookies.Token},
      config
    )
    .then(function (response) {
      console.log(response.data);
      setProductInfo(response.data)
      setProductOptions(JSON.parse(response.data.product_options))
    })
    .catch(function (error) {if (error.response.data.message==="Unauthenticated."){
        removeCookies('Name', { path: '/' });
        removeCookies('Token', { path: '/' });
        navigate("/user-login");
      }
      console.log(error);
    });
  },[isLoaded])

  return(
    <Modal onClose={props.onClose}>
      <button className={classes.close} onClick={props.onClose}>Πίσω</button>
      {productInfo &&
      <Fragment>
       <h2 className={classes.title}>Παραγγελία {productInfo.product_type}</h2>      
      <p>Είδος: {productInfo.product_type}</p>
      <p>Εταιρεία: {productInfo.manufacturer}</p>
      <p>Κωδικός: {productInfo.code}</p>
      
      </Fragment>
      }
      {productOptions &&
      <Fragment>
        <p>Ποσότητα: {productOptions.quantity}</p>
        <p>Τιμή Μέτρου: {productOptions.squarePrice}</p>
        <p>Πλάτος Παραυθύρου: {parseFloat(productOptions.windowWidth)}m</p>
        <p>Ύψος Παραυθύρου: {parseFloat(productOptions.windowHeight)}m</p>
        <p>Μέρος Τοποθέτησης: {productOptions.installPosition}</p>
        <p>Ανοιγόμενο ή Συρόμενο: {productOptions.wayOfOpening}</p>
        <p>Εμπόδιο: {productOptions.obstacle==="No"?"Όχι":"Ναι"}</p>
        {productOptions.obstacle!=="No"&&<p>Απόσταση από εμπόδιο: {productOptions.distance}cm</p>}
        {productOptions.aboveWindow&&<p>Πόσο πάνω από το παράθυρο θα τοποθετηθεί το roller: {productOptions.aboveWindow}cm</p>}
        <p>Πλάτος roller: {parseFloat(productOptions.rollerWidth)}m</p>
        <p>Ύψος roller: {parseFloat(productOptions.rollerHeight)}m</p>
        <p>Στήριξη σε: {productOptions.support}</p>
        <p>Θέση χειριστηρίων: {productOptions.controllerPlacement}</p>
        <p>Μήκος χειριστηρίων: {productOptions.controllerLengthPlacement}</p>
        <p>Προεξέχει κάποια λαβή στο παράθυρο: {productOptions.windowHandle}</p>
        <p>Σημειώσεις: {productOptions.freeText}</p>
        <p>Τιμή: {productOptions.totalPrice}€</p>
        {productOptions.discountPrice&&<p>Τιμή Έκπτωσης: {productOptions.discountPrice}€</p>}
        <p>Τοποθέτηση: {productOptions.installation==="oxi"?"Όχι":"Ναι"}</p>
        {productOptions.installPrice&&<p>Κόστος τοποθέτησης: {productOptions.installPrice}€</p>}
        <p>Πελάτης: {props.client_name}</p>
        {(productInfo.status==="submitted"||productInfo.status==="modify")&&<button className={classes.onOrder} onClick={props.onOrdered}>Παραγγέλθηκε;</button>}
      </Fragment>
      }
    </Modal>
  )
}

export default RollerView;
