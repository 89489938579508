import React,{Fragment, useState} from 'react';
import { Dropdown } from 'react-bootstrap';
import { Outlet, Link } from "react-router-dom";
import UserIcon from './UserIcon';

import classes from './Header.module.css';
import { useNavigate } from "react-router-dom";

const Header = (props) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setTimeout(() => {
      setIsOpen(false);
    }, 300);
  };
  const handleLinkClick = (url) => {
      navigate(url);
    };
  return (
    <Fragment>
      <header className={`${classes.header} ${classes.notPrintable}`}>
        <Link to="/" className={classes.menuItem}>Αρχική</Link>
        <Link to="installations" className={classes.menuItem}>Τοποθετήσεις</Link>
        <Link to="to-order" className={classes.menuItem}>Για Παραγγελία</Link>
        <Link to="in-progress" className={classes.menuItem}>Σε εξέλιξη</Link>
        <Link to="clients" className={classes.menuItem}>Πελάτες</Link>
        <Link to="app-history" className={classes.menuItem}>Ιστορικό App</Link>
        <Link to="turnover" className={classes.menuItem}>Τζίρος</Link>
        
        
        <Dropdown show={isOpen} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <Dropdown.Toggle className={classes.xmls} id="xmls-dropdown">
          <Link to="xmls" className={classes.menuItem}>Xmls</Link>
        </Dropdown.Toggle>

        {isOpen &&<Dropdown.Menu onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                  <Dropdown.Item href="/ezzo" onClick={() => handleLinkClick('/ezzo')}>Ezzo</Dropdown.Item>
                  <Dropdown.Item href="/isexan" onClick={() => handleLinkClick('/isexan')}>Isexan</Dropdown.Item>
                  <Dropdown.Item href="/madi" onClick={() => handleLinkClick('/madi')}>Madi</Dropdown.Item>
                  <Dropdown.Item href="/nima" onClick={() => handleLinkClick('/nima')}>Nima</Dropdown.Item>
                  <Dropdown.Item href="/royal-carpet" onClick={() => handleLinkClick('/royal-carpet')}>Royal Carpet</Dropdown.Item>
                  <Dropdown.Item href="/soulworks" onClick={() => handleLinkClick('/soulworks')}>Soulworks</Dropdown.Item>
                  <Dropdown.Item href="/teoran" onClick={() => handleLinkClick('/teoran')}>Teoran</Dropdown.Item>
                </Dropdown.Menu>}
      </Dropdown>  
      <Link to="user-login" className={classes.icon}><UserIcon/></Link>
      </header>
      <Outlet/>
    </Fragment>
  );
};

export default Header;
