import React,{useState,useEffect,Fragment} from 'react';
import Modal from '../UI/Modal';
import Button from '../UI/Button';
import classes from './ProductChangeHistoryView.module.css';
import { Navigate, Route, Routes, useNavigate,Outlet, Link  } from "react-router-dom";
import {useCookies} from 'react-cookie';
import axios from 'axios';


const ProductChangeHistory = (props) =>{
	const navigate = useNavigate();
	const [cookies, setCookies, removeCookies] = useCookies(['user']);
	const [product, setProduct] = useState('');

	useEffect(()=>{
		const config = {
      		headers: { 'Authorization': 'Bearer '+cookies.Token}
    	};
		axios.post('https://api.orders.e-interior.gr/api/product/'+props.id,{
      		key: cookies.Token},
      		config
    	).then(function (response) {
    		setProduct(response.data);
    		console.log(response.data)
    	}).catch(function (error) {
	      if (error.response.data.message==="Unauthenticated."){
	        removeCookies('Name', { path: '/' });
	        removeCookies('Token', { path: '/' });
	        navigate("/user-login");
	      }
	      console.log(error);
	    });
	},[])
	return(
		<Modal className={classes.productChange}>
		<button className={classes.close} onClick={props.onClose}>Πίσω</button>
		<div>
		<h2>Ιστορικό Κατάστασης</h2>
		<table className="table table-hover">
		<thead><tr>
			<th>Ημερομηνία</th>
			<th>Κατάσταση</th>
			<th>Σημειώσεις Ραφείου</th>
		</tr></thead>
		{product.status_history && product.status_history.map(result =>{
			let statusText=''
			switch(result.status) {
			    case "not_submitted_yet":
			      statusText="Δημιουργήθηκε";
			      break;
			    case "submitted":
			      statusText="Για Παραγγελία";
			      break;
			    case "ordered":
			      statusText="Παραγγέλθηκε";
			      break;
			    case "rafeio":
			      statusText="Όλα Ραφείο";
			      break;
			    case "ready":
			      statusText="Έτοιμο";
			      break;
			    default:
			      statusText=result.status;
			      break;
			  }
			return(
				<tr>
					<td>{new Date(result.created_at).toLocaleString()}</td>
					<td>{statusText}</td>
					<td>{result.note}</td>
				</tr>
			)
		})}
		</table>
		</div>
		</Modal>
	)
}
export default ProductChangeHistory;