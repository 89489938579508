import React,{useState,useEffect,Fragment} from 'react';
import Modal from '../UI/Modal';
import Button from '../UI/Button';
import classes from './Confirmation.module.css';



const DeletePayment = (props) =>{
	const handleYes = () =>{
	props.yesClick({id:props.id, type:props.type, how:props.how, amount:props.amount, date:props.date});
	}

	const handleNo = () =>{
	props.noClick({id:props.id});
	}
	
	return(
	<Modal className={classes.singleModal}>
		<p>Θέλετε να διαγραφεί η {props.type==="prokatavoli"?"Προκαταβολή":"Πληρωμή"} με {props.how==="metrita"?"μετρητά":props.how==="karta"?"κάρτα":"κατάθεση"}: {props.amount}€ ({props.date});</p>
		<div className={classes.buttonGrid}>
		<Button className={classes.confirmation} onClick={handleYes}>Ναι</Button>
		<Button className={classes.confirmation} onClick={handleNo}>Όχι</Button>
		</div>
	</Modal>
	)
}

export default DeletePayment;