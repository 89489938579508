import React, {useEffect, useState} from 'react';
import axios from 'axios';
import Card from '../UI/Card';
import classes from './Xmls.module.css';

import {useCookies} from 'react-cookie';
import { useNavigate } from "react-router-dom";

const Xmls = () =>{
	const navigate = useNavigate();
  	const [cookies, setCookies, removeCookies] = useCookies(['user']);

useEffect(() => {

  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: 'https://api.orders.e-interior.gr/api/verify',
    headers: { 
      'Accept': 'application/json', 
      'Authorization': 'Bearer '+cookies.Token, 
    },
  };

  axios.request(config)
  .then((response) => {
    console.log(response.data)
    if (response.data.message!=="Success"){
      removeCookies('Name', { path: '/' });
      removeCookies('Token', { path: '/' });
      navigate("/user-login");
    };
  })
  .catch((error) => {
    removeCookies('Name', { path: '/' });
    removeCookies('Token', { path: '/' });
    navigate("/user-login");
  });

}, []);

  	const handleLinkClick = (url) => {
  		navigate(url);
  	};
	//ezzo "https://www.percasa.gr/wp-content/uploads/wpallexport/exports/1213861efd33e838f1cc17549331f4e0/ezzo_products.xml"
	//isexan "https://b2b.isexan.gr/wp-content/uploads/woo-product-feed-pro/xml/JJ5tFvC5shYRZp56KGuUe0QYJlmIXAxk.xml"
	//newplan 'http://b2b.newplan.gr/ProductsXMLfullNew.aspx'
	//nima "https://api.nima.gr/home/PreviewXMLFile?ID=52c3b714-51cc-4036-a7a5-c419eb16540b"
	//royalCarpet 'https://feeds.carpet.gr/carpet.xml'
	//teroan "https://teoran.gr/index.php?route=extension/feed/teoran_feed_separated_high_res_qt"
  	return(
    <Card className={classes.XmlCard}>
      <div className={classes.XmlGrid}>
      	<div onClick={()=>handleLinkClick('/ezzo')}>Ezzo</div>
      	<div onClick={()=>handleLinkClick('/isexan')}>Isexan</div>
        <div onClick={()=>handleLinkClick('/madi')}>Madi</div>
      	<div onClick={()=>handleLinkClick('/nima')}>Nima</div>
      	<div onClick={()=>handleLinkClick('/royal-carpet')}>Royal Carpet</div>
      	<div onClick={()=>handleLinkClick('/soulworks')}>Soulworks</div>
      	<div onClick={()=>handleLinkClick('/teoran')}>Teoran</div>
      </div>
    </Card>
  )

}
export default Xmls;