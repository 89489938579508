import React,{useState,useEffect,Fragment} from 'react';
import Modal from '../UI/Modal';
import Button from '../UI/Button';
import classes from './Confirmation.module.css';

const DeletePermanently = (props) =>{
	const handleYes = () =>{
	props.yesClick({id:props.id});
	}

	const handleNo = () =>{
	props.noClick({id:props.id});
	}
	
	return(
	<Modal className={classes.singleModal}>
		<p>Να γίνει οριστική διαγραφή παραγγελίας;</p>
		<div className={classes.buttonGrid}>
		<Button className={classes.confirmation} onClick={handleYes}>Ναι</Button>
		<Button className={classes.confirmation} onClick={handleNo}>Όχι</Button>
		</div>
	</Modal>
	)
}

export default DeletePermanently;