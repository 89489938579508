import React,{useState,useEffect,Fragment} from 'react';
import Modal from '../UI/Modal';
import Button from '../UI/Button';
import classes from './DeficienciesModal.module.css';
import {useCookies} from 'react-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

const DeficienciesModal = (props) =>{

	const [cookies, setCookies, removeCookies] = useCookies(['user']);
	const [isLoaded, setIsLoaded] = useState(false);
	const [categoryTree, setCategoryTree] = useState([]);
	const close = <FontAwesomeIcon icon={faTimes} />

useEffect(()=>{
	setIsLoaded(true);
},[])

useEffect(()=>{
	let config = {
	  method: 'post',
	  maxBodyLength: Infinity,
	  url: 'https://api.orders.e-interior.gr/api/ellipseis/categoryTree',
	  headers: { 
	    'Accept': 'application/json', 
	    'Authorization': 'Bearer '+cookies.Token, 
	  },
	};

	axios.request(config)
	.then((response) => {
		setCategoryTree(response.data.eshopCategories)
		console.log(response.data.eshopCategories)

	})
	.catch((error) => {
	  console.log(error);
	});
},[isLoaded])


	const renderNestedStructure = (data) =>{
  return (
    <ul className={classes.categoryList}>
      {data.map((item) => (
        <li key={item.id}>
        <div className={classes.categoryGrid}>
          <label>
            <input 
            	className={classes.categoryCheckbox}
	            type="checkbox"
	            value={item.name}
	            checked={item.checked}
	            onChange={(event) => handleCategoriesChange(event, item.id, item.parent_id)}/>
            {item.name}
          </label>
          <input
          	className={classes.quantityInput}
            type="number"
            value={item.quantity_check}
            onChange={(event) => handleCategoryQuantityChange(event, item.id)}
          />
          </div>
          {item.sub.length > 0 && renderNestedStructure(item.sub)}
        </li>
      ))}
    </ul>
  );
}

	const handleCategoriesChange = (event, categoryId) => {
	    const updateCategoriesRecursively = (categories) => {
	    return categories.map((category) => {
	      if (category.id === categoryId) {
	        return {
	          ...category,
	          checked: event.target.checked,
	        };
	      }

	      if (category.sub.length > 0) {
	        const updatedSubcategories = updateCategoriesRecursively(
	          category.sub
	        );

	        return {
	          ...category,
	          sub: updatedSubcategories,
	        };
	      }

	      return category;
	    });
	  };

	  const updatedCategories = updateCategoriesRecursively(categoryTree);

	  setCategoryTree(updatedCategories);
  	};



  	const handleCategoryQuantityChange = (event,categoryId) => {
	    const updateCategoriesRecursively = (categories) => {
	    return categories.map((category) => {
	      if (category.id === categoryId) {
	        return {
	          ...category,
	          quantity_check: event.target.value,
	        };
	      }

	      if (category.sub.length > 0) {
	        const updatedSubcategories = updateCategoriesRecursively(
	          category.sub
	        );

	        return {
	          ...category,
	          sub: updatedSubcategories,
	        };
	      }

	      return category;
	    });
	  };

	  const updatedCategories = updateCategoriesRecursively(categoryTree);

	  setCategoryTree(updatedCategories);
  	}

	
	const handleSaveClick = () =>{
		function flattenCategories(categories) {
		  const flattenedCategories = [];

		  categories.forEach((category) => {
		    const { id, parent_id, name, quantity_check, checked } = category;

		    const relevantData = {
		      name,
		      cat_id: id, // Rename 'id' to 'cat_id'
		      parent_id,
		      quantity_check:quantity_check===''?null:quantity_check,
		      active:checked?1:0,
		    };

		    flattenedCategories.push(relevantData);

		    if (category.sub.length > 0) {
		      // If there are subcategories, recursively flatten them
		      const subcategories = flattenCategories(category.sub);
		      flattenedCategories.push(...subcategories);
		    }
		  });

		  return flattenedCategories;
		}

		// Assuming 'categoryTree' contains your category and subcategory data
		const relevantDataArray = flattenCategories(categoryTree);

		// Now 'relevantDataArray' contains both categories and subcategories on the same level
		console.log(relevantDataArray);

		let data = new FormData();
		data.append('categories', JSON.stringify(relevantDataArray));
		let config = {
	  method: 'post',
	  maxBodyLength: Infinity,
	  url: 'https://api.orders.e-interior.gr/api/ellipseis/categoryTree/save',
	  headers: { 
	    'Accept': 'application/json', 
	    'Authorization': 'Bearer '+cookies.Token, 
	  },
	  data : data
		};

		axios.request(config)
		.then((response) => {
			props.onClose();
		  console.log(response.data);
		})
		.catch((error) => {
		  console.log(error);
		});
	}


	const handleClearClick = () =>{
		function uncheckAllCategories(categories) {
		  return categories.map((category) => {
		    const updatedCategory = { ...category, checked: false, quantity_check: '' };

		    if (category.sub.length > 0) {
		      updatedCategory.sub = uncheckAllCategories(category.sub);
		    }

		    return updatedCategory;
		  });
		}
		console.log(uncheckAllCategories(categoryTree))
		setCategoryTree(uncheckAllCategories(categoryTree));

		function flattenCategories(categories) {
		  const flattenedCategories = [];

		  categories.forEach((category) => {
		    const { id, parent_id, name, quantity_check, checked } = category;

		    const relevantData = {
		      name,
		      cat_id: id, // Rename 'id' to 'cat_id'
		      parent_id,
		      quantity_check:null,
		      active:0,
		    };

		    flattenedCategories.push(relevantData);

		    if (category.sub.length > 0) {
		      // If there are subcategories, recursively flatten them
		      const subcategories = flattenCategories(category.sub);
		      flattenedCategories.push(...subcategories);
		    }
		  });

		  return flattenedCategories;
		}

		// Assuming 'categoryTree' contains your category and subcategory data
		const relevantDataArray = flattenCategories(categoryTree);

		// Now 'relevantDataArray' contains both categories and subcategories on the same level
		console.log(relevantDataArray);

		let data = new FormData();
		data.append('categories', JSON.stringify(relevantDataArray));
		let config = {
	  method: 'post',
	  maxBodyLength: Infinity,
	  url: 'https://api.orders.e-interior.gr/api/ellipseis/categoryTree/save',
	  headers: { 
	    'Accept': 'application/json', 
	    'Authorization': 'Bearer '+cookies.Token, 
	  },
	  data : data
		};

		axios.request(config)
		.then((response) => {
		  console.log(response.data);
		})
		.catch((error) => {
		  console.log(error);
		});
	}
	return(
		<Modal className={classes.singleModal} onClose={props.onClose}> 
		<div className={`${classes.categoryGrid} ${classes.categoryHeader}`}>
			<p>Κατηγορία</p>
			<p>Ποσότητα</p>
		</div>
		{renderNestedStructure(categoryTree)}
			<p className={classes.cancelButton} onClick={props.onClose} >{close}</p>
			<div className={classes.buttons}>
			<Button className={classes.confirmButton} onClick={handleSaveClick}>Αποθήκευση</Button>
			<Button className={classes.clearButton} onClick={handleClearClick}>Εκκαθάριση</Button>
			</div>
		</Modal>)
}
export default DeficienciesModal;