import React,{Fragment,useRef} from 'react';
import classes from './Input.module.css'

let importedClass="";
const importClass = (x) =>{
  importedClass=x;
}

const Input = React.forwardRef((props, ref) => {
  importClass(props.className);
  const vert=props.vert==="true";
  const mobileVert=props.vert==="true";
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const isPrice=props.isPrice==="true";
  return(
     <Fragment>
     {((!vert || windowSize.current[0]<=1066)&&!mobileVert) &&
       <div className={`${classes.input} ${importedClass}`}>
       <label htmlFor={props.input.id}>{props.label}</label>
       <input ref={ref} onChange={props.onChange} onBlur={props.onBlur} onFocus={props.onFocus} {...props.input}/>
       {isPrice&& '€'}
       </div>
     }
     {((vert && windowSize.current[0]>1066)||mobileVert)&&
       <div className={`${classes.input} ${importedClass}`}>
       <label htmlFor={props.input.id}><p>{props.label}</p><p className={classes.vertInput}><input ref={ref} onChange={props.onChange} onBlur={props.onBlur} onFocus={props.onFocus} {...props.input}/>{isPrice&& '€'}</p></label>
       </div>
     }
     </Fragment>

);
});

export default Input;
