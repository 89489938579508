import React,{useState,useEffect, Fragment} from 'react';
import { Navigate, Route, Routes, useNavigate,Outlet, Link  } from "react-router-dom";
import {useCookies} from 'react-cookie';
import Modal from '../UI/Modal';
import Input from '../UI/Input';
import Button from '../UI/Button';

import classes from './ClientView.module.css';
import axios from 'axios';

const ClientView = (props) =>{
  //states
  const navigate = useNavigate();
  const [cookies, setCookies, removeCookies] = useCookies(['user']);
  const [isLoaded, setIsLoaded] = useState(false);
  const [clientInfo, setClientInfo] = useState(null);

  useEffect(() => {
    setIsLoaded(true);
}, []);

  useEffect(() => {
    console.log('proped id is :' +props.id);
    const config = {
      headers: { 'Authorization': 'Bearer '+cookies.Token}
    };
    axios.post('https://api.orders.e-interior.gr/api/client/'+props.id,{
      key: cookies.Token},
      config
    )
    .then(function (response) {
      console.log(response.data);
      setClientInfo(response.data)
    })
    .catch(function (error) {
      if (error.response.data.message==="Unauthenticated."){
        removeCookies('Name', { path: '/' });
        removeCookies('Token', { path: '/' });
        navigate("/user-login");
      }
      console.log(error);
    });
  },[isLoaded])

  return(
    <Modal onClose={props.onClose} className={classes.singleModal}>
      <button className={classes.close} onClick={props.onClose}>Πίσω</button>
      {clientInfo && 
      <Fragment>
      <h2 className={classes.title}>Πελάτης</h2>
      <p>Όνομα: {clientInfo.first_name}</p>
      <p>Επώνυμο: {clientInfo.last_name}</p>
      <p>Τηλέφωνο: {clientInfo.phone}</p>
      <p>E-mail: {clientInfo.email}</p>
      <p>Διεύθυνση: {clientInfo.address}</p>
      <p>Πόλη: {clientInfo.city}</p>
      <p>T.K.: {clientInfo.area_code}</p>
      <p>Α.Φ.Μ.: {clientInfo.afm}</p>
      <p>Επάγγελμα: {clientInfo.industry}</p>
      <p>Επωνυμία: {clientInfo.business_name}</p>
      <p>Δ.Ο.Υ: {clientInfo.doy}</p>
      </Fragment>}
    </Modal>
  )
}

export default ClientView;
