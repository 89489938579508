import React,{Fragment,useState,useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faSave, faEye, faCheck, faStar} from '@fortawesome/free-solid-svg-icons'
import { Navigate, Route, Routes, useNavigate,Outlet, Link  } from "react-router-dom";
import isLoggedIn from '../UserLogin/UserLogin';
import {useCookies} from 'react-cookie';
import Input from '../UI/Input';
import classes from './SingleForWarehouse.module.css'
import axios from 'axios';
import moment from 'moment';

//view products
import FabricView from '../ViewProducts/FabricView'
import CurtainRodView from '../ViewProducts/CurtainRodView'
import PersidesView from '../ViewProducts/PersidesView'
import RollerView from '../ViewProducts/RollerView'
import XaliView from '../ViewProducts/XaliView'
import RomanView from '../ViewProducts/RomanView'
import CustomProductView from '../ViewProducts/CustomProductView'
import DiadromosView from '../ViewProducts/DiadromosView'

const view = <FontAwesomeIcon icon={faEye} />
const pencil = <FontAwesomeIcon icon={faPencilAlt} />
const save = <FontAwesomeIcon icon={faSave} />
const check = <FontAwesomeIcon icon={faCheck} />
const star = <FontAwesomeIcon icon={faStar}/>

const SingleForWarehouse = (props) =>{
  const [ready,setReady] = useState(0);

  const navigate = useNavigate();
  const [cookies, setCookies, removeCookies] = useCookies(['user']);
  const [isLoaded, setIsLoaded] = useState(false);
  const [orderDate, setOrderDate] = useState('');
  const [viewModal, setViewModal]=useState('');
  const [productStatus, setProductStatus]=useState('');
  const [statusChange, setStatusChange]=useState("");
  const [order, setOrder]=useState([]);
 
  useEffect(() => {
    setIsLoaded(true);

  }, []);
   
  useEffect(() => {
    if (!cookies.Token) {
      navigate("/user-login");
    }
  });

  useEffect(() => {
    setOrderDate(props.ordered_at ? props.ordered_at.split('T')[0]: 'Δεν έγινε παραγγελία ακόμα');
    switch(props.status){
      case "submitted":
        setProductStatus("Για Παραγγελία");
        break;
      case "ordered":
        setProductStatus("Παραγγέλθηκε");
        break;
      case "rafeio":
        setProductStatus("Ραφείο");
        break;
      case "ready":
        setProductStatus("Έτοιμο");
        break;
      default:
        setProductStatus("Για Παραγγελία");
        break;
    }
  },[isLoaded]);

  useEffect(()=>{
    props.onOrdered();
  },[statusChange])
  const [editClick,setEditClick] = useState(true);

  const orderStatusUpdate = () =>{
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://api.orders.e-interior.gr/api/order/'+props.order_id,
      headers: { 
        'Accept': 'application/json', 
        'Authorization': 'Bearer 5|X4kcu2RRJQOFjOZxtMb16xzk5dM1hgSTKeAPS9Lp', 
      },
    };

    axios.request(config)
    .then((response) => {
      setOrder(response.data);
    })
    .catch((error) => {
      console.log(error);
    });

  }

  useEffect(()=>{
    if (order.products){
      let prep = 0;
      let rdy = 0;
      let sent = 0;
      let repairing = false;
      let modifying = false;
      for (let product of order.products){
        if (product.status === "repair"){
          repairing=true;
        }
        if (product.status === "modify"){
          modifying=true;
        }
        if ((product.status === "ordered" || product.status === "rafeio" || product.status === "ready" ||product.status === "sent" ||product.status === "deleted" || product.status === "merikos"||product.status === "repair" )&&(!(order.status==="ypoloipo"||order.status==="arn-ypoloipo"||order.status==="communicated"||order.status==="completed"))){
          prep++;
        }
        if ((product.status === "ready"||product.status === "sent"||product.status === "deleted")&&(!(order.status==="ypoloipo"||order.status==="arn-ypoloipo"||order.status==="communicated"||order.status==="completed"))){
          rdy++;
        }
        if ((product.status === "sent"||product.status === "deleted")&&(!(order.status==="ypoloipo"||order.status==="arn-ypoloipo"||order.status==="completed"))){
          sent++;
        }
        }

        if (repairing){
            if (order.status!=="repairing"){
              const config = {
                headers: { 'Authorization': 'Bearer '+cookies.Token}
                };
              axios.post('https://api.orders.e-interior.gr/api/order/update',{
                key: cookies.Token,
                order_id: props.order_id,
                status: "repairing"
                },
                config)
              .then(function (response) {
              })
              .catch(function (error) {
                if (error.response.data.message==="Unauthenticated."){
                  removeCookies('Name', { path: '/' });
                  removeCookies('Token', { path: '/' });
                  navigate("/user-login");
                }
                console.log(error);
              });
            }
        }else if(modifying){
          if (order.status!=="modifying"){
            const config = {
                headers: { 'Authorization': 'Bearer '+cookies.Token}
                };
              axios.post('https://api.orders.e-interior.gr/api/order/update',{
                key: cookies.Token,
                order_id: props.order_id,
                status: "modifying"
                },
                config)
              .then(function (response) {
              })
              .catch(function (error) {
                if (error.response.data.message==="Unauthenticated."){
                  removeCookies('Name', { path: '/' });
                  removeCookies('Token', { path: '/' });
                  navigate("/user-login");
                }
                console.log(error);
              });
          }
        }else if (rdy===order.products.length){
            if (order.status!=="ready"){
              const config = {
              headers: { 'Authorization': 'Bearer '+cookies.Token}
              };
            axios.post('https://api.orders.e-interior.gr/api/order/update',{
              key: cookies.Token,
              order_id: props.order_id,
              status: "ready"
              },
              config)
            .then(function (response) {
            })
            .catch(function (error) {
              if (error.response.data.message==="Unauthenticated."){
                removeCookies('Name', { path: '/' });
                removeCookies('Token', { path: '/' });
                navigate("/user-login");
              }
              console.log(error);
            });
          }
          
        }else if (prep===order.products.length){
          if (order.status!=="preparing"){
              const config = {
              headers: { 'Authorization': 'Bearer '+cookies.Token}
              };
            axios.post('https://api.orders.e-interior.gr/api/order/update',{
              key: cookies.Token,
              order_id: props.order_id,
              status: "preparing"
              },
              config)
            .then(function (response) {
            })
            .catch(function (error) {
              if (error.response.data.message==="Unauthenticated."){
                removeCookies('Name', { path: '/' });
                removeCookies('Token', { path: '/' });
                navigate("/user-login");
                
              }
              console.log(error);
            });
          }
          }else{
            if (order.status!=="submitted"&&order.status!=="ypoloipo"&&order.status!=="arn-ypoloipo"){
              const config = {
              headers: { 'Authorization': 'Bearer '+cookies.Token}
              };
            axios.post('https://api.orders.e-interior.gr/api/order/update',{
              key: cookies.Token,
              order_id: props.order_id,
              status: "submitted"
              },
              config)
            .then(function (response) {

            })
            .catch(function (error) {
              if (error.response.data.message==="Unauthenticated."){
                removeCookies('Name', { path: '/' });
                removeCookies('Token', { path: '/' });
                navigate("/user-login");
              }
              console.log(error);
            });
          }   
      }

    }

  },[order])

  const handleEditClick = () =>{
    setEditClick(false);
  }
  const handleSaveClick = () =>{
    const date = new Date(orderDate).toISOString().slice(0,19).replace("T"," ");
      const config = {
      headers: { 'Authorization': 'Bearer '+cookies.Token}
      };
      axios.post('https://api.orders.e-interior.gr/api/product/update',{
        key: cookies.Token,
        product_id: props.id,
        ordered_at:date,
        status:"ready"
        },
        config)
      .then(function (response) {
        orderStatusUpdate();
        console.log(response);
      })
      .catch(function (error) {
        if (error.response.data.message==="Unauthenticated."){
          removeCookies('Name', { path: '/' });
          removeCookies('Token', { path: '/' });
          navigate("/user-login");
        }
        console.log(error);
      });
    setEditClick(true);
    props.onOrdered();
  }
  
  const handleViewOrderClick = (id) =>{
    const config = {
      headers: { 'Authorization': 'Bearer '+cookies.Token}
      };
      axios.post('https://api.orders.e-interior.gr/api/product/update',{
        key: cookies.Token,
        product_id: id,
        status:"ready",
        ordered_at: moment().format('YYYY-MM-DD')
        },
        config)
      .then(function (response) {
        orderStatusUpdate();
        setStatusChange(response.data)
        
      })
      .catch(function (error) {
        if (error.response.data.message==="Unauthenticated."){
          removeCookies('Name', { path: '/' });
          removeCookies('Token', { path: '/' });
          navigate("/user-login");
        }
        console.log(error);
      });
      setViewModal('');
  }

    const viewClickHandler = (id,productType) =>{
    if (productType.includes("Ύφασμα")){
      setViewModal(<FabricView id={id} client_name = {props.client} onClose={viewCloseHandler}  onOrdered={()=>handleViewOrderClick(id)}/>)
    }else if (productType.includes("Κουρτινόξυλο")){
      setViewModal(<CurtainRodView id={id} client_name = {props.client} onClose={viewCloseHandler}  onOrdered={()=>handleViewOrderClick(id)}/>)
    }else if (productType.includes("Περσίδες")){
      setViewModal(<PersidesView id={id} client_name = {props.client} onClose={viewCloseHandler}  onOrdered={()=>handleViewOrderClick(id)}/>)
    }else if (productType.includes("roller")){
      setViewModal(<RollerView id={id} client_name = {props.client} onClose={viewCloseHandler}  onOrdered={()=>handleViewOrderClick(id)}/>)
    }else if (productType.includes("χαλί")||productType.includes("Μοκέτα")||productType.includes("Πλαστικό")||productType.includes("Γκαζόν")||productType.includes("Σετ Κρεβατοκάμαρας")){
      setViewModal(<XaliView id={id} client_name = {props.client} onClose={viewCloseHandler}  onOrdered={()=>handleViewOrderClick(id)}/>)
    }else if (productType.includes('Ρόμαν')||productType.includes('Ψευτορόμαν')){
      setViewModal(<RomanView id={id} client_name = {props.client} onClose={viewCloseHandler} onOrdered={()=>handleViewOrderClick(id)}/> )
    }else if (productType.includes('Διάδρομος')){
      setViewModal(<DiadromosView id={id} client_name = {props.client} onClose={viewCloseHandler} onOrdered={()=>handleViewOrderClick(id)}/>)
    }else if (productType.includes('Άλλο είδος')){
      setViewModal(<CustomProductView id={id} client_name = {props.client} onClose={viewCloseHandler}  onOrdered={()=>handleViewOrderClick(id)}/>)
    }
  }
  const viewCloseHandler = ()=>{
    setViewModal('');
  }

  const handleChange = (event) =>{
    setOrderDate(event.target.value);
  }
  const handleProductStatusChange = (event) =>{
    const config = {
      headers: { 'Authorization': 'Bearer '+cookies.Token}
      };
      axios.post('https://api.orders.e-interior.gr/api/product/update',{
        key: cookies.Token,
        product_id: props.id,
        status:event.target.value
        },
        config)
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        if (error.response.data.message==="Unauthenticated."){
          removeCookies('Name', { path: '/' });
          removeCookies('Token', { path: '/' });
          navigate("/user-login");
        }
        console.log(error);
      });
  }

  const handleCheckClick = (event) =>{
    const config = {
      headers: { 'Authorization': 'Bearer '+cookies.Token}
      };
      axios.post('https://api.orders.e-interior.gr/api/product/update',{
        key: cookies.Token,
        product_id: props.id,
        status:"ready",
        ordered_at: new Date().toISOString().slice(0,19).replace("T"," ")
        },
        config)
      .then(function (response) {
        orderStatusUpdate();
        props.onOrdered();
      })
      .catch(function (error) {
        if (error.response.data.message==="Unauthenticated."){
          removeCookies('Name', { path: '/' });
          removeCookies('Token', { path: '/' });
          navigate("/user-login");
        }
        console.log(error);
      });

  }
    return(

      <Fragment>
      {viewModal}
        <tr className={classes.orderRow}>
          <td onClick={()=>viewClickHandler(props.id,props.product)}>{props.order_id}</td>
          <td onClick={()=>viewClickHandler(props.id,props.product)}>{props.product}</td>
          <td onClick={()=>viewClickHandler(props.id,props.product)}>{props.code}{props.quantity&&" x "+props.quantity}</td>
          <td onClick={()=>viewClickHandler(props.id,props.product)}>{JSON.parse(props.options).notes?JSON.parse(props.options).notes:JSON.parse(props.options).freeText}</td>
          <td onClick={()=>viewClickHandler(props.id,props.product)}>{props.vip === 1 &&(<span>{star}</span>)}{props.client}</td>
          {editClick && <td onClick={()=>viewClickHandler(props.id,props.product)}>{orderDate==='Δεν έγινε παραγγελία ακόμα'?orderDate:orderDate.split(' ')[0]}</td>}
          {!editClick &&
            <td>
            <input onChange={handleChange} value={orderDate.split(' ')[0]} type='date'>
            </input></td>}
          <td onClick={()=>viewClickHandler(props.id,props.product)}>{props.manufacturer}</td>
          {editClick &&<td onClick={handleEditClick}>{pencil}</td>}
          {!editClick && <td onClick={handleSaveClick}>{save}</td>}
          <td onClick={handleCheckClick}>{check}</td>
        </tr>

      </Fragment>)
  }
  

export default SingleForWarehouse;
