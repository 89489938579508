import React,{useState,useEffect,Fragment} from 'react';
import Modal from '../UI/Modal';
import Button from '../UI/Button';
import classes from './ClientAlreadyExists.module.css';



const ClientAlreadyExists = (props) =>{
	const handleYes = () =>{
	props.yesClick({match:props.client});
	}

	const handleNo = () =>{
	props.noClick({id:props.id});
	}
	
	return(
	<Modal className={classes.singleModal}>
		<p>Το τηλέφωνο υπάρχει ήδη, θέλετε να φορτώσετε τα στοιχεία του πελάτη;</p>
		<div className={classes.buttonGrid}>
		<Button className={classes.confirmation} onClick={handleYes}>Ναι</Button>
		<Button className={classes.confirmation} onClick={handleNo}>Όχι</Button>
		</div>
	</Modal>
	)
}

export default ClientAlreadyExists;