import React,{useState,useEffect, Fragment} from 'react';
import { Navigate, Route, Routes, useNavigate,Outlet, Link  } from "react-router-dom";
import {useCookies} from 'react-cookie';
import Modal from '../UI/Modal';
import Input from '../UI/Input';
import Button from '../UI/Button';
import OrderContext from '../../store/order-context'
import classes from './Views.module.css';
import axios from 'axios';

const FabricView = (props) =>{
  //states
  const navigate = useNavigate();
  const [cookies, setCookies, removeCookies] = useCookies(['user']);
  const [isLoaded, setIsLoaded] = useState(false);
  const [productInfo, setProductInfo] = useState(null);
  const [productOptions, setProductOptions] = useState(null);
  const [finishString, setFinishString] = useState('');

  useEffect(() => {
    setIsLoaded(true);
}, []);

  useEffect(() => {
    const config = {
      headers: { Authorization: "Bearer 5|X4kcu2RRJQOFjOZxtMb16xzk5dM1hgSTKeAPS9Lp"}
    };
    axios.post('https://api.orders.e-interior.gr/api/product/'+props.id,{
      key: cookies.Token},
      config
    )
    .then(function (response) {
      console.log(response.data);
      setProductInfo(response.data)
      setProductOptions(JSON.parse(response.data.product_options))
    })
    .catch(function (error) {
      if (error.response.data.message==="Unauthenticated."){
        removeCookies('Name', { path: '/' });
        removeCookies('Token', { path: '/' });
        navigate("/user-login");
      }
      console.log(error);
    });
  },[isLoaded])

  useEffect(() =>{
    if (productOptions){
      switch (productOptions.finish) {
      case 'gazi':
        setFinishString('Απλό γαζί');
        break;
      case '5cm':
        setFinishString('Φάσα 5cm');
        break;
      case '7cm':
        setFinishString('Φάσα 7cm');
        break;
      case '10cm':
        setFinishString('Φάσα 10cm');
        break;
      }
    }
  },[productOptions])
  return(
    <Modal onClose={props.onClose}>
      <button className={classes.close} onClick={props.onClose}>Πίσω</button>
      {productInfo && 
      <Fragment>
      <h2 className={classes.title}>Παραγγελία {productInfo.product_type}</h2>
      <p>Είδος: {productInfo.product_type}</p>
      <p>Εταιρεία: {productInfo.manufacturer}</p>
      <p>Κωδικός: {productInfo.code}</p>
      
      </Fragment>
      }
      {productOptions &&
      <Fragment>
      <p>Ποσότητα: {productOptions.quantity}</p>
      <p>Τιμή Μέτρου: {productOptions.price}€</p>
      {productOptions.fabricMeters!=0&&<p>Μέτρα ύφασμα: {parseFloat(productOptions.fabricMeters)}m</p>}
      {(!productInfo.product_type.includes("Τραπεζομάντηλο")&&!productInfo.product_type.includes("Άλλη κατασκευή"))&&
      <Fragment>
      
      {productOptions.rodWidth&&<p>Πλάτος Κουρτινόξυλου: {parseFloat(productOptions.rodWidth)}m</p>}
      </Fragment>}
      {(productInfo.product_type.includes("Τραπεζομάντηλο"))&&
      <Fragment>
      <p>Πλάτος τραπεζομάντηλου (m): {parseFloat(productOptions.widthTrapezi)}m</p>
      <p>Μήκος τραπεζομάντηλου (m): {parseFloat(productOptions.lengthTrapezi)}m</p>
      </Fragment>
      }
      {productInfo.product_type.includes("Τραπεζομάντηλο")&&
        <p>Τελείωμα: {finishString}</p>
      }
      {productOptions.sewingSelection!="0" && <p>Ραφή: {productOptions.sewingSelection}</p>}
      {(productOptions.sewingSelection==="Άλλη ραφή"||productInfo.product_type.includes("Άλλη κατασκευή"))&&
      <Fragment>
      <p>Περιγραφή/Όνομα Ραφής: {productOptions.rafiName}</p>
      <p>Χρέωση ραφής: {productOptions.rafiPrice}€</p>
      </Fragment>
      }
      {productOptions.sewingSelection.includes('Τρουκ')&&<p>Χρώμα/Κωδ.ΤΡΟΥΚ: {productOptions.troukCode}</p>}
      {console.log(productOptions.curtainFinish)}
      {(productInfo.product_type.includes('βέργα')||productInfo.product_type.includes('σιδηρόδρομο'))&&
      <p>Τελείωμα Κουρτίνας: {productOptions.curtainFinish}</p>
      }
      {productOptions.curtainFinish==="Στρίφωμα" && <p>Πόσο στρίφωμα: {productOptions.curtainFinishInfo}</p>}
      {productOptions.curtainFinish==="Άλλο" && <p>Σημείωση τελειώματος: {productOptions.curtainFinishInfo}</p>}
      {productOptions.calculatedWidth.length>0 && <p>Πλάτος: {parseFloat(productOptions.calculatedWidth)}m</p>}
      {productOptions.curtainHeight.length>0 && <p>Ύψος Κουρτίνας: {parseFloat(productOptions.curtainHeight)}m</p>}
      {(!productInfo.product_type.includes("Τραπεζομάντηλο")&&!productInfo.product_type.includes("Άλλη κατασκευή"))&&
      <Fragment>
      {(productOptions.typeKefSelection!=0)?
      <p><strong><u>Κεφαλάκι: {productOptions.typeKefSelection}cm</u></strong></p>
      :<p><strong><u>Κεφαλάκι: Όχι</u></strong></p>}
      </Fragment>
      }
      {productOptions.hookToFloorHeight.length>0 &&
      <Fragment>
        <p>Ύψος από γατζάκι έως δάπεδο: {parseFloat(productOptions.hookToFloorHeight)}m</p>
        {(productInfo.product_type.includes("οβάλ")||productInfo.product_type.includes("βέργα"))&&<p>(βάζετε το γατζακι στη 2η σειρά της κουρτίνας για να έρθει ίσα ίσα με το δάπεδο)</p>}
      </Fragment>}
      {productOptions.rodToFloorHeight.length>0 &&<p>Ύψος από πάνω μέρος βέργας έως δάπεδο: {productOptions.rodToFloorHeight}</p>}
      {productOptions.tieSelection!="0" &&<p>Δέστρες: {productOptions.tieSelection}</p>}
      {(!productInfo.product_type.includes("Τραπεζομάντηλο")&&!productInfo.product_type.includes("Άλλη κατασκευή") &&productOptions.foldSelection!="0") &&<p>Φύλλα: {productOptions.foldSelection}</p>}
      <p>Τοποθέτηση: {productOptions.installation==="nai"?"Ναι":"Όχι"}</p>
      {productOptions.notes.length>0 && <p>Σημειώσεις: {productOptions.notes}</p>}
      <p>Τιμή: {productOptions.totalPrice}€</p>
      <p>Πελάτης: {props.client_name}</p>
      {(productInfo.status==="submitted"||productInfo.status==="modify")&&<button className={classes.onOrder} onClick={props.onOrdered}>Παραγγέλθηκε;</button>}
      </Fragment>
      }

    </Modal>
  )
}

export default FabricView;
