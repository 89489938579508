    import React from 'react';
    import 'bootstrap/dist/css/bootstrap.min.css';
    import classes from './AppHistoryImport.module.css';
    //Axios for get request
    import axios from 'axios';
    import Input from '../UI/Input';
    import Button from '../UI/Button';
    class AppHistoryImport extends React.Component {
     //initialize an object's state in a class
      constructor(props) {
        super(props)
          this.state = {
            data: [],
            brandParameter:'Όλα',
            brandOptions: [],
            quantityParameter: 'Όλα',
            quantityOptions : ['Όλα', 0, 1, 2, 3],
            dateParameter: 'Όλα',
            dateOptions: ['Όλα', 'Εβδομάδα', 'Μήνας'],
            quantityTypeParameter: 'Όλα',
            quantityTypeOptions: ['Όλα', 'Προστέθηκαν', 'Αφαιρέθηκαν', 'Δημιουργήθηκαν'],
            lastChangeParameter: '0'
            }
          this.handleBrandChange = this.handleBrandChange.bind(this);
          this.handleQuantityChange = this.handleQuantityChange.bind(this);
          this.handleDateChange = this.handleDateChange.bind(this);
          this.handleQuantityTypeChange = this.handleQuantityTypeChange.bind(this);
          this.handleLastChangeClick = this.handleLastChangeClick.bind(this);
          this.handleButtonClick = this.handleButtonClick.bind(this);
          this.handleClearClick = this.handleClearClick.bind(this);


          }

          handleBrandChange(e) {
            this.setState({brandParameter: e.target.value}); 
          }
          handleQuantityChange(e){
            this.setState({quantityParameter: e.target.value});
          }
          handleDateChange(e){
            this.setState({dateParameter: e.target.value});
          }
          handleQuantityTypeChange(e){
            this.setState({quantityTypeParameter: e.target.value});
          }
          handleLastChangeClick(){
            if (this.state.lastChangeParameter=='0'){
              console.log('check');
              this.setState({lastChangeParameter: '1'})
            }else{
              console.log('check2');
              this.setState({lastChangeParameter: '0'})
            }
          } 
          handleButtonClick(){
            let brand = '';
            let quantity = '';
            let date  = '';
            let quantityType = '';
            if (this.state.brandParameter=='Όλα'){
              brand = 'All';
            }else{
              brand = this.state.brandParameter;
            }
            if (this.state.quantityParameter=='Όλα'){
              quantity = 'All';
            }else{
              quantity = this.state.quantityParameter;
            }
            if (this.state.dateParameter == 'Όλα'){
              date = 'All';
            }else if(this.state.dateParameter=="Εβδομάδα"){
              date = "This Week";
            }else if(this.state.dateParameter=="Μήνας"){
              date = "This Month";
            }
            if (this.state.quantityTypeParameter == 'Όλα'){
              quantityType = 'All';
            }else if(this.state.quantityTypeParameter=='Προστέθηκαν'){
              quantityType = 'Added';
            }else if (this.state.quantityTypeParameter=='Αφαιρέθηκαν'){
              quantityType = 'Removed';
            }else {
              quantityType = 'Created';
            }

            let data = {apikey: '9g82n398nqf98nf9q839g8h@93w8nfa!'};

            let config = {
              method: 'post',
              maxBodyLength: Infinity,
              url: 'https://e-interior.gr/save.php?call=getHistory&brand='+brand+'&quantity='+quantity+'&date='+date+'&quantity_type='+quantityType+'&last='+this.state.lastChangeParameter,
              data,
              headers: { 
                'Content-Type': 'application/x-www-form-urlencoded', 
              }
              
            };
            axios.request(config).then(res => 
            {
            
            this.setState({data: res.data});
               }); 
          }
          handleClearClick(){
            this.setState({
              brandParameter:'Όλα',
              quantityParameter: 'Όλα',
              dateParameter: 'Όλα',
              quantityTypeParameter: 'Όλα',
              lastChangeParameter: '0'
            })
            axios.get('https://e-interior.gr/save.php?call=getHistory&brand=all&quantity=all&date=all&quantity_type=all&last=0')
            .then(res => 
            {
            
            this.setState({data: res.data});
               }); 

          }

            componentDidMount(){
            //get request
            let data = {apikey: '9g82n398nqf98nf9q839g8h@93w8nfa!'};

            let config = {
              method: 'post',
              maxBodyLength: Infinity,
              url: 'https://e-interior.gr/save.php?call=getBrands',
              data,
              headers: { 
                'Content-Type': 'application/x-www-form-urlencoded', 
              }
              
            };
            axios.request(config).then(res => 
                {
                this.setState({brandOptions: res.data});
                this.setState({brandOptions: ["Όλα",...this.state.brandOptions]})
                }).catch((error) => {
                    console.log(error);
                  }); 

                // const x = JSON.parse(this.state.data)
                let brand = '';
                let quantity = '';
                let date  = '';
                let quantityType = '';
                if (this.state.brandParameter=='Όλα'){
                  brand = 'All';
                }else{
                  brand = this.state.brandParameter;
                }
                if (this.state.quantityParameter=='Όλα'){
                  quantity = 'All';
                }else{
                  quantity = this.state.quantityParameter;
                }
                if (this.state.dateParameter == 'Όλα'){
                  date = 'All';
                }else if(this.state.dateParameter=="Εβδομάδα"){
                  date = "This Week";
                }else if(this.state.dateParameter=="Μήνας"){
                  date = "This Months";
                }
                if (this.state.quantityTypeParameter == 'Όλα'){
                  quantityType = 'All';
                }else if(this.state.quantityTypeParameter=='Προστέθηκαν'){
                  quantityType = 'Added';
                }else if (this.state.quantityTypeParameter=='Αφαιρέθηκαν'){
                  quantityType = 'Removed';
                }else {
                  quantityType = 'Created';
                }


                axios.post(
                    'https://e-interior.gr/save.php?call=getHistory&brand=' +
                      brand +
                      '&quantity=' +
                      quantity +
                      '&date=' +
                      date +
                      '&quantity_type=' +
                      quantityType +
                      '&last=' +
                      this.state.lastChangeParameter,
                    data,
                    {
                      headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                      }
                    }
                  )
                  .then((res) => {
                    this.setState({ data: res.data });
                    console.log(res.data);
                  })
                  .catch((error) => {
                    console.log(error);
                  });
                }
        
     
      render() {
       
        return (
          
          <div className="maincontainer">
            <h1 className="mr-5 ml-5 mt-5">Ιστορικό App</h1>
            <div className={classes.grid}>
              <div><p>Εταιρεία:</p>
              <select value={this.state.brandParameter} onChange={this.handleBrandChange}>

              {Array.isArray(this.state.brandOptions) && this.state.brandOptions.map((option) => (

                <option value={option}>{option}</option>

              ))}

              </select>
              </div>

              <div><p>Ποσότητα:</p>
              <select value={this.state.quantityParameter} onChange={this.handleQuantityChange}>

              {this.state.quantityOptions.map((option) => (

                <option value={option}>{option}</option>

              ))}

              </select>
              </div>

              <div><p>Ημερομηνία:</p>
              <select value={this.state.dateParameter} onChange={this.handleDateChange}>

              {this.state.dateOptions.map((option) => (

                <option value={option}>{option}</option>

              ))}

              </select>
              </div>

              <div><p>Κίνηση:</p>
              <select value={this.state.quantityTypeParameter} onChange={this.handleQuantityTypeChange}>

              {this.state.quantityTypeOptions.map((option) => (

                <option value={option}>{option}</option>

              ))}

              </select>
              </div>
              <div><p>Τελευταία κίνηση ανά προϊόν:</p>
              <input id='lastChange' type='checkbox' checked={this.state.lastChangeParameter=="1"} onClick={this.handleLastChangeClick}></input>
              </div>
              <div className={classes.filter}>
              <Button onClick={this.handleButtonClick}>Φίλτρο</Button>
              <Button onClick={this.handleClearClick} className={classes.clearButton}>Καθαρισμός</Button>
              </div>
            </div>
            <div className="container-lg mb-5 mt-5 text-left">
            
            <table>
              <thead>
                <tr>
                  <th>EAN</th>
                  <th>Χρήστης</th>
                  <th>Όνομα Προϊόντος</th>
                  <th>Εταιρεία</th>
                  <th>Αρχική Ποσότητα</th>
                  <th>Τελική Ποσότητα</th>
                  <th>Ημερομηνία</th>
                </tr>
              </thead>
              <tbody>
              {Array.isArray(this.state.data) && this.state.data.map((result) => {
                return (
                     <tr className={(result.product_old_quantity-result.product_new_quantity>0) ? `${classes.removed}` : `${classes.added}`}>
                      <td>{result.product_ean}</td>
                      <td>{result.username}</td>
                      <td>{result.product_name}</td>
                      <td>{result.brand}</td>
                      <td>{result.product_old_quantity}</td>
                      <td>{result.product_new_quantity}</td>
                      <td>{result.date}</td>
                    </tr>
                )
              })}
              </tbody>
            </table>
           
                
          </div>
         
          </div>
         
    )
    };
    }
    export default AppHistoryImport;