import React, {useState, useEffect} from 'react';
import Header from './components/Layout/Header';
import MainButtons from './components/MainButtons/MainButtons';
import UserLogin from './components/UserLogin/UserLogin';
import NewOrder from './components/NewOrder/NewOrder';
import OrdersInProgress from './components/OrdersInProgress/OrdersInProgress';
import OrderHistory from './components/OrderHistory/OrderHistory';
import Placements from './components/Placements/Placements';
import ToOrder from './components/ToOrder/ToOrder';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Header/>
      </header>
    </div>
  );
}

export default App;
