import React,{useState,useEffect, Fragment} from 'react';
import axios from 'axios';
import {useCookies} from 'react-cookie';
import { Navigate, Route, Routes, useNavigate,Outlet, Link, useLocation  } from "react-router-dom";
import classes from './ViewOrderNotSubmitted.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import Modal from '../UI/Modal';
import Button from '../UI/Button';

const trash = <FontAwesomeIcon icon={faTrash} />



const ViewOrderNotSubmitted = (props) => {
	const navigate = useNavigate();
	const [cookies, setCookies, removeCookies] = useCookies(['user']);
	const [productList, setProductList] = useState([]);
	const [emptyProducts, setEmptyProducts] = useState(true);
	useEffect(() => {
	  const config = {
	    headers: { 'Authorization': 'Bearer '+cookies.Token}
	  };
	  axios.post('https://api.orders.e-interior.gr/api/products',{
	    key: cookies.Token},
	    config
	  )
	  .then(function (response) {
	    setProductList(response.data);

	  })
	  .catch(function (error) {
	    if (error.response.data.message==="Unauthenticated."){
	          navigate("/user-login");
	        }
	    console.log(error);
	  });
	  },[])
	const deleteClick = () =>{
    	props.deleteClick({id:props.id})
  	}
	const handleEditClick = () =>{
		props.returnClick({id:props.id})
	}
	return(
		<Fragment>
			<Modal className={classes.productModal} onClose={props.onClose}>
			<p className={classes.deleteButton} title="Οριστική Διαγραφή" onClick={deleteClick}>{trash}</p>
			<div className={classes.productListHeader}>
		      <span>Είδος</span>
		      <span>Κωδικός/Περιγραφή</span>
		      <span>Τιμή</span>
		      <span>Έκπτωση</span>
		      <span>Τιμή με τοποθέτηση</span>
		    </div>
		    {productList.map(({id,order_id,product_type,code,product_options,status})=>{
		      if (order_id==props.id){
		        let totalPrice =  Number(JSON.parse(product_options).totalPrice);
		        let discountPrice = JSON.parse(product_options).discountPrice ? Number(JSON.parse(product_options).discountPrice):false;
		        let totalPriceDiscount = JSON.parse(product_options).totalPriceDiscount ? Number(JSON.parse(product_options).totalPriceDiscount):false;
		        let installPrice = JSON.parse(product_options).installPrice ? Number(JSON.parse(product_options).installPrice):0;
		        let installTotal = JSON.parse(product_options).installPrice ? JSON.parse(product_options).totalPriceDiscount ? Math.round((installPrice+totalPriceDiscount)*100)/100:(JSON.parse(product_options).discountPrice ? Math.round((installPrice+discountPrice)*100)/100:Math.round((installPrice+totalPrice)*100)/100):'-';
		        if (emptyProducts){
		        	setEmptyProducts(false);
		        }
		        return (<Fragment>
		        <div className={`${classes.productList} ${status==='deleted' && classes.deleted}`}>
		        <span>{product_type}</span>
		        <span>{code}{JSON.parse(product_options).quantity&&" x "+JSON.parse(product_options).quantity} τμχ</span>
		        <span>{totalPrice ?totalPrice+'€':'-'}</span>
		        <span>{totalPriceDiscount ? (totalPriceDiscount+'€'):discountPrice ? (discountPrice+'€'):'-'}</span>
		        <span>{installTotal!=='-'?installTotal+'€':'-'} </span>
		        </div>
		        <span className={classes.divider}></span>
		        </Fragment>)
		      }
		      }
		    )}
		    {emptyProducts && <p className={classes.emptyNotice}>Η παραγγελία δεν έχει προϊόντα</p>}
		    <div className={classes.buttonGrid}>
			<Button className={classes.confirmation} onClick={handleEditClick}>Επεξεργασία</Button>
			<Button className={classes.confirmation} onClick={props.onClose}>Άκυρο</Button>
			</div>
		    </Modal>
		</Fragment>
	)


}

export default ViewOrderNotSubmitted;