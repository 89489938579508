import React, {Fragment, useEffect, useState} from 'react';
import Card from '../UI/Card';
import axios from 'axios';
import classes from './XmlTables.module.css';
import SingleXMLItem from './SingleXMLItem';
import LoadingSpinner from '../UI/LoadingSpinner';
import {useCookies} from 'react-cookie';
import { useNavigate } from "react-router-dom";

const Soulworks = () =>{
	const navigate = useNavigate();
  	const [cookies, setCookies, removeCookies] = useCookies(['user']);
  	const [productList, setProductList] = useState([]);
  	const [axiosLoaded, setAxiosLoaded] = useState(false);
  	const [error, setError] = useState(false);

useEffect(() => {

  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: 'https://api.orders.e-interior.gr/api/verify',
    headers: { 
      'Accept': 'application/json', 
      'Authorization': 'Bearer '+cookies.Token, 
    },
  };

  axios.request(config)
  .then((response) => {
    console.log(response.data)
    if (response.data.message!=="Success"){
    	removeCookies('Name', { path: '/' });
    	removeCookies('Token', { path: '/' });
      navigate("/user-login");
    };
  })
  .catch((error) => {
  	if (error.response.data.message==="Unauthenticated."){
	  	removeCookies('Name', { path: '/' });
	  	removeCookies('Token', { path: '/' });
	    navigate("/user-login");
	  }
  });

}, []);


useEffect(()=>{
	let data = {apikey: '9g82n398nqf98nf9q839g8h@93w8nfa!'};

	let config = {
	  method: 'post',
	  maxBodyLength: Infinity,
	  url: 'https://e-interior.gr/save.php?call=getXMLProductsSoulworks',
	  data,
	  headers: { 
	    'Content-Type': 'application/x-www-form-urlencoded', 
	  }
	  
	};

	axios.request(config)
	.then((response) => {
		console.log(response.data)
	  setProductList(response.data);
  	setAxiosLoaded(true);
	})
	.catch((error) => {
	  console.log(error);
	  setAxiosLoaded(true);
    setError(true);
	});
},[])



return(
	<Card className={classes.productListCard}>
	<p>Παρακάτω εμφανίζονται τα προϊόντα που δεν υπάρχουν πλέον στο xml της εταιρείας ή είναι εκτός αποθέματος αλλά στο eshop έχουν ποσότητα τουλάχιστον 1.
Έτσι μπορείς να ενημερώσεις το ποσοστό εκπτωσης που δεν ενημερώνεται πλέον αυτόματα.
Όσα είναι με τιρκουάζ απόχρωση σημαίνει ότι τα έχεις ήδη ενημερώσει.</p>
		<h1 className={classes.title}>SOULWORKS</h1>
		{axiosLoaded ?
		<Fragment>
			{Array.isArray(productList)&&productList.length>0?
			    <table className={classes.table}>
			      <thead>
			        <tr>
			          <th>Κωδικός Προιόντος</th>
			          <th>Όνομα</th>
			          <th>Τιμή</th>
			          <th>Έκπτωση</th>
			          <th>Τιμή με έκπτωση</th>
			          <th></th>
			        </tr>
			      </thead>
			      <tbody>
			        {productList.map((item) => (
			        	<SingleXMLItem
			        		id={item.id}
			        		Code={item.upc}
			        		Description={item.title}
			        		BarCodePrice={item.price}
			        		discount={item.discountPercent}
			        		priceWithDiscount={item.discountPrice}
			        		call="updateXMLProductSoulworks"
			        		changed={item.manual_change_discount}
			        	/>
			        ))}
			      </tbody>
			    </table>
			  :!error?<p>Δεν υπάρχουν αυτή τη στιγμή προϊόντα</p>:<p>Υπήρχε πρόβλημα κατά την πρόσβαση στην βάση</p>}
			</Fragment>
			:<LoadingSpinner/>}
	</Card>
	)
};
export default Soulworks;