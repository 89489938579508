import React,{useState,useEffect, Fragment} from 'react';
import { Navigate, Route, Routes, useNavigate,Outlet, Link  } from "react-router-dom";
import {useCookies} from 'react-cookie';
import Modal from '../../UI/Modal';
import Input from '../../UI/Input';
import Button from '../../UI/Button';
import OrderContext from '../../../store/order-context'
import classes from './Fabric.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { Typeahead } from 'react-bootstrap-typeahead'; 

const Fabrics = (props) =>{

  //states
  const navigate = useNavigate();
  const [cookies, setCookies, removeCookies] = useCookies(['user']);
  //basic information
  const [companies, setCompanies]=useState('');
  const [companySelection, setCompanySelection] = useState([]);
  const [companyDropdown, setCompanyDropdown] = useState('');
  const [sku, setSku]=useState("");
  const [quantity, setQuantity]=useState('1');
  const [price, setPrice]=useState("");
  const [rafiName, setRafiName]=useState("");
  const [rafiPrice, setRafiPrice]=useState("");
  const [troukCode, setTroukCode]=useState("");
  const [curtainFinishInfo, setCurtainFinishInfo]=useState("");
  
  //selection
  const [typeSelection,setTypeSelection]=useState("xwris");
  const [typeKefSelection,setTypeKefSelection]=useState("0");
  const [sewingSelection,setSewingSelection]=useState("0");
  const [tieSelection,setTieSelection]=useState("0");
  const [foldSelection,setFoldSelection]=useState("ena");
  const [curtainFinishSelection, setCurtainFinishSelection]=useState("varidi");

  //dimensions
  const [fabricMeters, setFabricMeters]=useState('');
  const [widthTrapezi, setWidthTrapezi]=useState('');
  const [lengthTrapezi, setLengthTrapezi]=useState('');
  const [rodWidth, setRodWidth]=useState("");
  const [calculatedWidth, setCalculatedWidth]=useState("");
  const [suggestedWidth, setSuggestedWidth]=useState("");
  const [curtainHeight,setCurtainHeight]=useState("");
  const [suggestedCurtainHeight, setSuggestedCurtainHeight]=useState("");
  const [hookToFloorHeight,setHookToFloorHeight]=useState("");
  const [rodToFloorHeight,setRodToFloorHeight]=useState("");

  const [finish, setFinish]=useState("gazi");
  const [installSelection,setInstallSelection]=useState("oxi");
  const [notes,setNotes]=useState("")

  //total
  const [discount, setDiscount] = useState(false);
  const [discountPrice, setDiscountPrice] = useState('');
  const [totalPriceDiscount, setTotalPriceDiscount]=useState('');
  const [totalPrice,setTotalPrice] = useState("");
  
  const [errorMessage, setErrorMessage] =useState("");

  const [isLoaded, setIsLoaded] = useState(false);
  const [options,setOptions]=useState({id:'',label:''});
  const [buttonDisabled, setButtonDisabled] = useState(false);
  
  useEffect(() => {
    setIsLoaded(true);
}, []);
  useEffect(() => {
    const config = {
      headers: { 'Authorization': 'Bearer '+cookies.Token}
    };
    axios.post('https://api.orders.e-interior.gr/api/general/init',{
      key: cookies.Token},
      config
    )
    .then(function (response) {
      setCompanies(response.data[0].companies);

    })
    .catch(function (error) {
      if (error.response.data.message==="Unauthenticated."){
          removeCookies('Name', { path: '/' });
          removeCookies('Token', { path: '/' });
          navigate("/user-login");
        }
      console.log(error);
    });

  },[isLoaded])

  const handleFocus = (event) => {
    const { name, value } = event.target;
    if (value){
      const inputValue = parseFloat(value);



      switch (name) {
        case 'fabricMeters':
          setFabricMeters(inputValue);
          break;
        case 'rodWidth':
          setRodWidth(inputValue);
          break;
        case 'rodToFloorHeight':
          setRodToFloorHeight(inputValue);
          break;
        case 'hookToFloorHeight':
          setHookToFloorHeight(inputValue);
          break;
        case 'curtainHeight':
          setCurtainHeight(inputValue);
          break;
        case 'widthTrapezi':
          setWidthTrapezi(inputValue);
          break;
        case 'lengthTrapezi':
          setLengthTrapezi(inputValue);
          break;
        case 'calculatedWidth':
          setCalculatedWidth(inputValue);
          break;
        default:
          break;
      }
  }
  };
  const handleBlur = (event) => {
    const { name, value } = event.target;
    if (value){
      const inputValue = parseFloat(value);
      const formattedValue = inputValue.toFixed(3) + 'm';


      switch (name) {
        case 'fabricMeters':
          setFabricMeters(formattedValue);
          break;
        case 'rodWidth':
          setRodWidth(formattedValue);
          break;
        case 'rodToFloorHeight':
          setRodToFloorHeight(formattedValue);
          break;
        case 'hookToFloorHeight':
          setHookToFloorHeight(formattedValue);
          break;
        case 'curtainHeight':
          setCurtainHeight(formattedValue);
          break;
        case 'widthTrapezi':
          setWidthTrapezi(formattedValue);
          break;
        case 'lengthTrapezi':
          setLengthTrapezi(formattedValue);
          break;
        case 'calculatedWidth':
          setCalculatedWidth(formattedValue);
          break;
        default:
          break;
      }
  }
  };
  
  const companySelectionHandler = (event) =>{
    setCompanySelection(event.target.value);
  }

  const skuChangeHandler = (event)=>{
    setSku(event.target.value);
  }
  const quantityChangeHandler = (event)=>{
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    value = value.trim();
    if (value === '') {
      setQuantity(''); // Set quantity as an empty string if input is empty
    } else {
      value = parseInt(value);
      if (isNaN(value)) {
        setQuantity(''); // Set quantity as an empty string if value is NaN
      } else {
        setQuantity(value);
      }
    }
  }
  const priceChangeHandler = (event)=>{
    // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setPrice(value);
    }
  }
  const selectTypeHandler = (event) =>{
    setTypeSelection(event.target.value);
    if (event.target.value==="xwris"||event.target.value==="trapezomantilo"||event.target.value==="allo"){
      setSewingSelection("0")
    }else if(event.target.value==="esoxi"){
      setSewingSelection("free")
    }else{
      setSewingSelection("common_statheri");
    }
  }
  const selectTypeKefHandler = (event) =>{
    setTypeKefSelection(event.target.value);
  }
  const rodWidthChangeHandler = (event) =>{
    // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setRodWidth(value);
    }
  }
  const selectSewingHandler = (event) =>{
    setTypeKefSelection("0");
    setSewingSelection(event.target.value);
  }
  const selectTieHandler = (event) =>{
    setTieSelection(event.target.value);
  }
  const selectCurtainFinishHandler = (event) =>{
    setCurtainFinishSelection(event.target.value)
  }
  const selectFoldHandler = (event) =>{
    setFoldSelection(event.target.value);
  }
  const selectInstallHandler = (event) =>{
    setInstallSelection(event.target.value);
  }
  const finishHandler = (event) =>{
    setFinish(event.target.value);
  }
  const fabricMetersChangeHandler = (event) =>{
    // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setFabricMeters(value);
    }
  }

  const troukCodeChangeHandler = (event) =>{
    setTroukCode(event.target.value);
  }
  const curtainFinishInfoChangeHandler = (event) =>{
    setCurtainFinishInfo(event.target.value)
  }
  useEffect(()=>{
    setCurtainFinishInfo('');
  },[curtainFinishSelection])
  const widthTrapeziChangeHandler = (event) =>{
    // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setWidthTrapezi(value);
    }
  }
  const lengthTrapeziChangeHandler = (event) =>{
    // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setLengthTrapezi(value);
    }
  }
  const notesChangeHandler = (event) =>{
    setNotes(event.target.value);
  }

  const discountChangeHandler = (event) =>{
    if (discount){
      setDiscount(false);
    }else{
      setDiscount(true);
    }
  }
  const discountPriceChangeHandler = (event) =>{
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setDiscountPrice(value);
    }
  }
  const totalPriceChangeHandler = (event) =>{
    // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setTotalPrice(value);
    }
  }

  const rafiNameChangeHandler = (event) =>{
    setRafiName(event.target.value);
  }
  const rafiPriceChangeHandler = (event) =>{
    // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setRafiPrice(value);
    }
  }
  const curtainHeightChangeHandler = (event) =>{
    // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setCurtainHeight(value);
    }
  }
  const hookToFloorHeightChangeHandler = (event) =>{
    // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setHookToFloorHeight(value);
    }
  }
  const rodToFloorHeightChangeHandler = (event) =>{
    // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setRodToFloorHeight(value);
    }
  }
  const calculatedWidthChangeHandler = (event) =>{
    // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setCalculatedWidth(value);
    }
  }

useEffect(()=>{
  let tieNum = 0;
  if (tieSelection==="thilaki"){
    tieNum = 5;
  }else if(tieSelection==="xrits-xrats"){
    tieNum = 10;
  }
  if (typeSelection !=="xwris" && typeSelection !=="trapezomantilo" && typeSelection !== "allo"){
    switch (sewingSelection){
        case "0":
          break;
        case "custom":
          setTotalPrice(Math.round(((parseFloat(price)+parseFloat(rafiPrice))*(parseFloat(calculatedWidth))+tieNum)*quantity*100)/100);
          break;
        case "free":
        case "free2_5":
          setTotalPrice(Math.round(((parseFloat(price))*(parseFloat(calculatedWidth))+tieNum)*quantity*100)/100);
          break;
        case "tunnel":
          setTotalPrice(Math.round(((parseFloat(price)+2)*(parseFloat(calculatedWidth))+tieNum)*quantity*100)/100);
          break;
        case "common_statheri":
        case "common_diafani":
        case "statheri":
        case "diafani":
          setTotalPrice(Math.round(((parseFloat(price)+4)*(parseFloat(calculatedWidth))+tieNum)*quantity*100)/100);
          break;
        case "dipieto":
        case "tripieto":
        case "vanity":
          setTotalPrice(Math.round(((parseFloat(price)+8)*(parseFloat(calculatedWidth))+tieNum)*quantity*100)/100);
          break;
        case "new_tunnel":
          setTotalPrice(Math.round(((parseFloat(price)+6)*(parseFloat(calculatedWidth))+tieNum)*quantity*100)/100);
          break;
        case "Trouk_40_Alum":
          setTotalPrice(Math.round(((parseFloat(price)+15)*(parseFloat(calculatedWidth))+tieNum)*quantity*100)/100);
          break;
        case "Trouk_40":
        case "Trouk_50":
          setTotalPrice(Math.round(((parseFloat(price)+20)*(parseFloat(calculatedWidth))+tieNum)*quantity*100)/100);
          break; 
        case "wave":
          setTotalPrice(Math.round(((parseFloat(price)+7)*(parseFloat(calculatedWidth))+tieNum)*quantity*100)/100);
      }
    }else if(typeSelection ==="xwris"){
      setTotalPrice(Math.round((parseFloat(price)*parseFloat(fabricMeters))*quantity*100)/100);
    }else if(typeSelection ==="trapezomantilo" || typeSelection === "allo"){
      setTotalPrice(Math.round((parseFloat(price)*parseFloat(fabricMeters)+parseFloat(rafiPrice))*quantity*100)/100);
    }
},[sewingSelection,calculatedWidth,price,fabricMeters, tieSelection, rafiPrice, quantity]);

  //calculations
  useEffect(() =>{
    let rafi = 0;
    switch(sewingSelection){
      case "0":
        rafi=1;
        break;
      case "custom":
        rafi=1;
        break;
      case "statheri":
        rafi = 1.5;
        break;
      case "new_tunnel":
        rafi = 1.8;
        break;
      case "common_statheri":
      case "diafani":
      case "tunnel":
      case "free":
        rafi = 2;
        break;
      case "Trouk_40_Alum":
      case "Trouk_40":
      case "Trouk_50":
        rafi = 2.2;
        break;
      case "common_diafani":
      case "dipieto":
      case "tripieto":
      case "vanity":
      case "free2_5":
        rafi = 2.5;
        break;
      case "wave":
        rafi = 2;
        break;
      default:
        break;
    }
    if (foldSelection==="dyo"){
      setSuggestedWidth(Math.round((parseFloat(rodWidth)*rafi+0.3)*100)/100);
    }else{
     setSuggestedWidth(Math.round(parseFloat(rodWidth)*rafi*100)/100); 
    }
    
  },[sewingSelection,rodWidth,typeSelection,foldSelection])

  useEffect(()=>{
    let suggCalc=0;
    if (typeSelection==="verga"){// verga and oval exc trouk tunel
      
        if (sewingSelection.toLowerCase().includes('trouk')){
          suggCalc=Math.round((parseFloat(rodToFloorHeight)+0.04)*100)/100
        }else if(sewingSelection.toLowerCase().includes('tunnel')){
          suggCalc=Math.round((parseFloat(rodToFloorHeight)+0.04)*100)/100
        }else{
          suggCalc=Math.round((parseFloat(hookToFloorHeight))*100)/100
        }
        
      }else if(typeSelection==="oval"){
        if (sewingSelection.toLowerCase().includes('trouk')||sewingSelection.toLowerCase().includes('tunnel')){
          suggCalc=Math.round((parseFloat(rodToFloorHeight)+0.04)*100)/100
        }else{
          suggCalc=Math.round((parseFloat(hookToFloorHeight))*100)/100
        }
      }else if(typeSelection==="esoxi"){//kourtinoxylo esoxh
        if (sewingSelection.toLowerCase().includes('trouk')||sewingSelection.toLowerCase().includes('tunnel')){
          suggCalc=Math.round((parseFloat(rodToFloorHeight)+0.04)*100)/100
        }else{
          suggCalc=Math.round((parseFloat(hookToFloorHeight)-0.02)*100)/100
        }
      }
    setSuggestedCurtainHeight(parseFloat(suggCalc))
    
  },[typeSelection,sewingSelection,hookToFloorHeight,rodToFloorHeight])

  const handleSaveClick = () =>{
  if ( companySelection!='0'&&sku!=''&&price!=''&&
    !buttonDisabled){
    setButtonDisabled(true);
    const config = {
      headers: { 'Authorization': 'Bearer '+cookies.Token}
    };

    let typeString ='';
    switch(typeSelection){
    case 'xwris':
      typeString="Ύφασμα χωρίς ραφή"
      break;
    case 'verga':
      typeString="Ύφασμα για βέργα με κρίκους"
      break;
    case 'oval':
      typeString="Ύφασμα για οβάλ σιδηρόδρομο"
      break;
    case 'esoxi':
      typeString="Ύφασμα για σιδηρόδρομο σε εσοχή"
      break;
    case 'trapezomantilo':
      typeString="Ύφασμα για Τραπεζομάντηλο"
      break;
    case 'allo':
      typeString="Ύφασμα για Άλλη κατασκευή"
      break;
    }
    let sewingString='';
    switch(sewingSelection){
      case "0":
        sewingString="0"
        break;
      case "custom":
        sewingString="Άλλη ραφή"
        break;
      case "free":
        sewingString="Απλή ραφή Συν/νη πιέτα (1:2) ΔΩΡΕΑΝ"
        break;
      case "free2_5":
        sewingString="Απλή ραφή Συν/νη πιέτα (1:2,5) ΔΩΡΕΑΝ"
        break;
      case "common_statheri":
        sewingString="Απλή Συν/νη Σταθερή (1:2) (Σύνηθες) +4€/m"
        break;
      case "common_diafani":
        sewingString="Απλή Συν/νη Διάφανη (1:2,5) (Σύνηθες) +4€/m"
        break;
      case "statheri":
        sewingString="Απλή Συν/νη Σταθερή (1:1,5) +4€/m"
        break;
      case "diafani":
        sewingString="Απλή Συν/νη Διάφανη (1:2) +4€/m"
        break;
      case "dipieto":
        sewingString="Δίπιετο (1:2,5) +8€/m"
        break;
      case "tripieto":
        sewingString="Τρίπιετο (1:2,5) +8€/m"
        break;
      case "vanity":
        sewingString="Vanity (1:2,5) +8€/m"
        break;
      case "tunnel":
        sewingString="Τούνελ Απλό (1:2) +2€/m"
        break;
      case "new_tunnel":
        sewingString="Νέο Τούνελ (1:1,8) +6€/m"
        break;
      case "Trouk_40_Alum":
        sewingString="Τρουκ (1:2,2) Φ40 ΑΛΟΥΜ +15€/m"
        break;
      case "Trouk_40":
        sewingString="Τρουκ (1:2,2) Φ40 +20€/m"
        break;
      case "Trouk_50":
        sewingString="Τρουκ (1:2,2) Φ50 +20€/m"
        break;
      case "wave":
        sewingString="WAVE (1:2) +7€/m"
        break;
      default:
        break;
      }
    let curtainFinishString='';
    switch(curtainFinishSelection){
      case "varidi":
        curtainFinishString= "Βαρίδι";
        break;
      case "strifwma":
        curtainFinishString= "Στρίφωμα";
        break;
      case "gazi":
        curtainFinishString= "Γαζί";
        break;
      case "allo":
        curtainFinishString= "Άλλο";
        break;
    }

    let tieString='';
    switch(tieSelection){
    case "0":
      tieString="0"
      break;
    case "thilaki":
      tieString="Θυλάκι +5,00€"
      break;
    case "xrits-xrats":
      tieString="Χριτς - Χρατς +10,00€"
      break;
    }

    let foldString='';
    switch(foldSelection){
      case "0":
        foldString="0"
        break;
      case "ena":
        foldString="Ένα μονοκόμματο"
        break;
      case "dyo":
        foldString="Δύο φύλλα αριστερά-δεξιά"
        break;
    }


    const productOptions = JSON.stringify({
    quantity:quantity,
    price: price,
    typeKefSelection: typeKefSelection,
    rodWidth: rodWidth,
    sewingSelection: sewingString,
    calculatedWidth: calculatedWidth,
    fabricMeters: fabricMeters,
    curtainHeight: curtainHeight,
    troukCode: troukCode,
    widthTrapezi: widthTrapezi,
    lengthTrapezi: lengthTrapezi,
    hookToFloorHeight:hookToFloorHeight,
    rodToFloorHeight:rodToFloorHeight,
    curtainFinish:curtainFinishString,
    curtainFinishInfo:curtainFinishInfo,
    tieSelection: tieString,
    foldSelection: foldString,
    finish: finish,
    installation: installSelection,
    notes: notes,
    rafiName:rafiName,
    rafiPrice:rafiPrice,
    discount: discount,
    discountPrice: discount ? discountPrice:'',
    totalPriceDiscount: discount ? totalPriceDiscount:'',

    totalPrice: totalPrice

    })
    axios.post('https://api.orders.e-interior.gr/api/product/create',{
      key: cookies.Token,
      order_id: props.OrderId?props.OrderId:cookies.OrderId,
      product_type: typeString,
      manufacturer: companySelection[0].label,
      code: sku,
      product_options: productOptions,
      status: "submitted",
      ordered_at: null
      },
      config
    )
    .then(function (response) {
      console.log(response.data);
      props.onSave({data:response.data})
      setButtonDisabled(false);
    })
    .catch(function (error) {
      setButtonDisabled(false);
      if (error.response.data.message==="Unauthenticated."){
          removeCookies('Name', { path: '/' });
          removeCookies('Token', { path: '/' });
          navigate("/user-login");
        }
      console.log(error);
    });
  }else{
    setErrorMessage('Λείπουν υποχρεωτικά πεδία');
  }
  }
  useEffect(()=>{
      if (companies){
        setOptions(companies.map((key) => ({
                id: key,
                label: `${key}`,
              })))}
    }, [companies])

  useEffect(()=>{
      setTotalPriceDiscount(Number(discountPrice)*Number(quantity));
    },[discountPrice,quantity])

  return(
    <Modal className={classes.singleModal} onClose={props.onClose}>
      <h2 className={classes.title}>Παραγγελία Υφάσματος</h2>
      {errorMessage}
      <div>
      
        <div className={classes.textarea}>
        <label htmlFor="sku">Κωδικός/Περιγραφή: </label>
        <textarea id="sku" rows="2" value={sku} onChange={skuChangeHandler}></textarea>
        </div>
        <div className={classes.searchBlock}>
        <span>
          <label htmlFor="type">Εταιρεία: </label>
          {options.length>0 ? 
            <Typeahead
                className={classes.typeaheadSelect}
                id="basic-typeahead-single"
                labelKey="label"
                onChange={setCompanySelection}
                options={options}
                placeholder=""
                defaultSelected={companySelection}
                selected={companySelection}
            />:
            <FontAwesomeIcon className={classes.loadingIcon} icon={faCircleNotch} spin />
          }
        </span>
      </div>
      
        <Input
        onChange={priceChangeHandler}
        label="Τιμή Μέτρου:"
        isPrice="true"
        input={{
          id: 1,
          type: 'text',
          value:price
        }} />
        <div className={classes.searchBlock}>
          <span>
            <label htmlFor="type">Ύφασμα για: </label>
            <select id="type" value={typeSelection} name="type" onChange={selectTypeHandler}>
              <option value="xwris">Ύφασμα χωρίς Ραφή</option>
              <option value="verga">Βέργα με κρίκους</option>
              <option value="oval">Σιδηρόδρομος οβάλ</option>
              <option value="esoxi">Σιδηρόδρομος σε εσοχή </option>
              <option value="trapezomantilo">Τραπεζομάντηλο</option>
              <option value="allo">Άλλη κατασκευή</option>
            </select>
          </span>
        </div>
        {typeSelection ==="xwris" &&
        <Input
        onChange={fabricMetersChangeHandler}
        onBlur={handleBlur}
        onFocus={handleFocus}
        label="Μέτρα Ύφασμα:"
        input={{
          id: 1,
          name: 'fabricMeters',
          type: 'text',
          value: fabricMeters
        }} />
        }

      { (typeSelection !=="trapezomantilo" && typeSelection !== "allo") &&
      <Input onChange={rodWidthChangeHandler}
      onBlur={handleBlur}
      onFocus={handleFocus}
      label="Πλάτος Κουρτινόξυλου (m):"
      input={{
        id: 1,
        name: 'rodWidth',
        type: 'text',
          value:rodWidth
      }} />
    }
      { (typeSelection !== "xwris" && typeSelection !=="trapezomantilo" && typeSelection !== "allo") &&
        <Fragment>
          <div className={classes.searchBlock}>
            <span>
              <label htmlFor="sewing">Ραφή: </label>
              <select id="sewing" name="sewing" value={sewingSelection} onChange={selectSewingHandler}>
                <option value="0"> </option>
                <option value="free">Απλή ραφή Συν/νη πιέτα (1:2) ΔΩΡΕΑΝ</option>
                <option value="free2_5">Απλή ραφή Συν/νη πιέτα (1:2,5) ΔΩΡΕΑΝ</option>
                <option value="common_statheri">Απλή Συν/νη Σταθερή (1:2) (Σύνηθες) +4€/m</option>
                <option value="common_diafani">Απλή Συν/νη Διάφανη (1:2,5) (Σύνηθες) +4€/m</option>
                <option value="statheri">Απλή Συν/νη Σταθερή (1:1,5) +4€/m</option>
                <option value="diafani">Απλή Συν/νη Διάφανη (1:2) +4€/m</option>
                <option value="dipieto">Δίπιετο (1:2,5) +8€/m</option>
                <option value="tripieto">Τρίπιετο (1:2,5) +8€/m</option>
                <option value="vanity">Vanity (1:2,5) +8€/m</option>
                <option value="tunnel">Τούνελ Απλό (1:2) +2€/m</option>
                <option value="new_tunnel">Νέο Τούνελ (1:1,8) +6€/m</option>
                <option value="Trouk_40_Alum">Τρουκ (1:2,2) Φ40 ΑΛΟΥΜ +15€/m</option>
                <option value="Trouk_40">Τρουκ (1:2,2) Φ40 +20€/m</option>
                <option value="Trouk_50">Τρουκ (1:2,2) Φ50 +20€/m</option>
                <option value="wave">WAVE (1:2) +7€/m</option>
                <option value="custom">Άλλη ραφή</option>
              </select>
            </span>
          </div>
          {sewingSelection === "custom" &&
          <Fragment>
            <Input
              onChange={rafiNameChangeHandler}
              label="Περιγραφή/Όνομα Ραφής:"
              input={{
                id: 1,
                type: 'text',
              value: rafiName
              }} /> 
            <Input
              onChange={rafiPriceChangeHandler}
              label="Χρέωση Ραφής:"
              input={{
                id: 1,
                type: 'text',
              value: rafiPrice
              }} />
          </Fragment>}
          {sewingSelection.includes("Trouk")&&
            <Input onChange={troukCodeChangeHandler}
              label="Χρώμα/Κωδ.ΤΡΟΥΚ:"
              input={{
                id: 1,
                type: 'text',
                  value:troukCode
              }} />
          }
          <div className={classes.searchBlock}>
            <span>
              <label htmlFor="type">Τελείωμα κουρτίνας: </label>
              <select id="type" value={curtainFinishSelection} name="type" onChange={selectCurtainFinishHandler}>
                <option value="varidi">Βαρίδι</option>
                <option value="strifwma">Στρίφωμα</option>
                <option value="gazi">Γαζί</option>
                <option value="allo">Άλλο</option>
              </select>
            </span>
          </div>
          {curtainFinishSelection==="strifwma"&&
            <Input onChange={curtainFinishInfoChangeHandler}
              label="Πόσο στρίφωμα;"
              input={{
                id: 1,
                type: 'text',
                  value:curtainFinishInfo
              }} />
          }
          {curtainFinishSelection==="allo"&&
            <Input onChange={curtainFinishInfoChangeHandler}
              label="Σημείωση τελειώματος:"
              input={{
                id: 1,
                type: 'text',
                  value:curtainFinishInfo
              }} />
          }
          <div className={classes.searchBlock}>
            <span>
              <label htmlFor="fold">Φύλλα: </label>
              <select id="fold" value={foldSelection} name="fold" onChange={selectFoldHandler}>
                <option value="ena">Ένα μονοκόμματο</option>
                <option value="dyo">Δύο φύλλα αριστερά-δεξιά</option>
              </select>
            </span>
          </div>
        </Fragment>
      }
        {(typeSelection !=="trapezomantilo" && typeSelection !== "allo"&&!['dipieto','tripieto','vanity','Trouk_40_Alum','Trouk_40','Trouk_50'].includes(sewingSelection)) &&
         <div className={classes.searchBlock}>
          <span>
            <label htmlFor="typeKef" >Κεφαλάκι: </label>
            <select id="typeKef" value={typeKefSelection}name="typeKef" onChange={selectTypeKefHandler}>
              <option value="0">Όχι</option>
              <option value="1">1cm</option>
              <option value="2">2cm</option>
              <option value="3">3cm</option>
              <option value="4">4cm</option>
            </select>
          </span>
        </div>
      }
        
        {sewingSelection !=="0" &&
        <Fragment>

        <div>
        <Input onChange={calculatedWidthChangeHandler}
        onBlur={handleBlur}
        onFocus={handleFocus}
        label="Μέτρα/Πλάτος Κουρτίνας:"
        input={{
          id: 1,
          name: 'calculatedWidth',
          type: 'text',
          value:calculatedWidth
        }} />
        <p className={classes.hint}>{suggestedWidth>0&&<Fragment>Προτεινόμενα Μέτρα/Πλάτος Κουρτίνας: {suggestedWidth} m</Fragment>}</p>
        </div>
        
        { ["tunnel","new_tunnel","Trouk_40_Alum","Trouk_40","Trouk_50"].includes(sewingSelection)
        ?<Input 
        onChange={rodToFloorHeightChangeHandler}
        onBlur={handleBlur}
        onFocus={handleFocus}
        label="Ύψος από πάνω μέρος βέργας έως δάπεδο (m):"
        input={{
          id: 1,
          name: 'rodToFloorHeight',
          type: 'text',
          value:rodToFloorHeight
        }} />
        : sewingSelection !=="0" &&
        <Input 
        onChange={hookToFloorHeightChangeHandler}
        onBlur={handleBlur}
        onFocus={handleFocus}
        label="Ύψος από γατζάκι έως δάπεδο (m):"
        input={{
          id: 1,
          name: 'hookToFloorHeight',
          type: 'text',
          value:hookToFloorHeight
        }} />
        
      }
      <div>
        <Input
        onChange={curtainHeightChangeHandler}
        onBlur={handleBlur}
        onFocus={handleFocus}
        label="Ύψος Κουρτίνας (m):"
        input={{
          id: 1,
          name: 'curtainHeight',
          type: 'text',
          value:curtainHeight
        }} />
        <p className={classes.hint}>{(parseFloat(hookToFloorHeight)>0||parseFloat(rodToFloorHeight)>0) && "Προτεινόμενο ύψος: "+parseFloat(suggestedCurtainHeight)+"m"} {(typeSelection==="oval"||typeSelection==="verga")&&<p className={classes.hint}>(βάζετε το γατζακι στη 2η σειρά της κουρτίνας για να έρθει ίσα ίσα με το δάπεδο)</p>}</p>
        </div>
        <div className={classes.searchBlock}>
          <span>
            <label htmlFor="tie">Δέστρες: </label>
            <select id="tie" value={tieSelection} name="tie" onChange={selectTieHandler}>
              <option value="0">Όχι</option>
              <option value="thilaki">Θυλάκι +5,00€</option>
              <option value="xrits-xrats">Χριτς - Χρατς +10,00€</option>
            </select>
          </span>
        </div>
        
        <div className={classes.searchBlock}>
          <span>
            <label htmlFor="install">Τοποθέτηση: </label>
            <select id="install" value={installSelection} name="install" onChange={selectInstallHandler}>
              <option value="oxi">Όχι</option>
              <option value="nai">Ναι</option>
            </select>
          </span>
        </div>
        <div className={classes.textarea}>
        <label htmlFor="sku">Σημειώσεις: </label>
        <textarea id="sku" rows="2" value={notes} onChange={notesChangeHandler}></textarea>
        </div>
        </Fragment>}

      </div>
      {(typeSelection !== "trapezomantilo" && typeSelection !=="allo")&&
      <Fragment>
        <div className={classes.totalPrice}>
          <p>{totalPrice>0 && ('Τιμή: '+totalPrice+'€')} </p>
          </div>
          {totalPrice>0 && <div>
          <Button className={classes.discountBtn} onClick={discountChangeHandler}>Έκπτωση;</Button>
          {!discount && <p></p>}
          {discount && 
          <Fragment>
          <Input
          onChange={discountPriceChangeHandler}
          label="Τιμή με έκπτωση:"
          isPrice="true"
          input={{
            id: 1,
            type: 'text',
            value: discountPrice
          }} />
          <p>Σύνολο με έκπτωση: {totalPriceDiscount}€</p>
          </Fragment>}
          </div>}
          </Fragment>}
        {typeSelection === "trapezomantilo" &&
          <Fragment>
            <Input
              onChange={fabricMetersChangeHandler}
              onBlur={handleBlur}
              onFocus={handleFocus}
              label="Μέτρα ύφασμα:"
              input={{
                id: 1,
                name: 'fabricMeters',
                type: 'text',
                value: fabricMeters
              }} />
            <Input
              onChange={widthTrapeziChangeHandler}
              onBlur={handleBlur}
              onFocus={handleFocus}
              label="Πλάτος τραπεζομάντηλου (m):"
              input={{
                id: 1,
                name: 'widthTrapezi',
                type: 'text',
                value: widthTrapezi
              }} />
              <Input
              onChange={lengthTrapeziChangeHandler}
              onBlur={handleBlur}
              onFocus={handleFocus}
              label="Μήκος τραπεζομάντηλου (m):"
              input={{
                id: 1,
                name: 'lengthTrapezi',
                type: 'text',
                value: lengthTrapezi
              }} />
              <div className={classes.searchBlock}>
                <span>
                  <label htmlFor="finish">Τελείωμα: </label>
                  <select id="finish" value={finish} name="finish" onChange={finishHandler}>
                    <option value="gazi">Απλό γαζί</option>
                    <option value="5cm">Φάσα 5cm</option>
                    <option value="7cm">Φάσα 7cm</option>
                    <option value="10cm">Φάσα 10cm</option>
                  </select>
                </span>
              </div>
              <Input
              onChange={rafiPriceChangeHandler}
              label="Χρέωση ραφής:"
              isPrice="true"
              input={{
                id: 1,
                type: 'text',
                value: rafiPrice
              }} />
            <div className={classes.totalPrice}>
              <p>{totalPrice>0 && ('Τιμή: '+totalPrice+'€')} </p>
              </div>
              {totalPrice>0 && <div>
              <Button className={classes.discountBtn} onClick={discountChangeHandler}>Έκπτωση;</Button>
              {!discount && <p></p>}
              {discount && 
              <Fragment>
              <Input
              onChange={discountPriceChangeHandler}
              label="Τιμή με έκπτωση:"
              isPrice="true"
              input={{
                id: 1,
                type: 'text',
                value: discountPrice
              }} />
              <p>Σύνολο με έκπτωση: {totalPriceDiscount}€</p>
              </Fragment>}
            </div>}
            <div className={classes.textarea}>
            <label htmlFor="sku">Σημειώσεις: </label>
            <textarea id="sku" rows="2" value={notes} onChange={notesChangeHandler}></textarea>
            </div>
          </Fragment>
        }
        {typeSelection === "allo" &&
          <Fragment>
            <Input
              onChange={fabricMetersChangeHandler}
              onBlur={handleBlur}
              onFocus={handleFocus}
              label="Μέτρα ύφασμα:"
              input={{
                id: 1,
                name: 'fabricMeters',
                type: 'text',
                value: fabricMeters
              }} />
            <Input
              onChange={rafiNameChangeHandler}
              label="Περιγραφή/Όνομα Ραφής:"
              input={{
                id: 1,
                type: 'text',
              value: rafiName
              }} /> 
              <Input
              onChange={rafiPriceChangeHandler}
              label="Χρέωση ραφής:"
              isPrice="true"
              input={{
                id: 1,
                type: 'text',
                value: rafiPrice
              }} />
            <div className={classes.totalPrice}>
              <p>{totalPrice>0 && ('Τιμή: '+totalPrice+'€')} </p>
              </div>
              {totalPrice>0 && <div>
              <Button className={classes.discountBtn} onClick={discountChangeHandler}>Έκπτωση;</Button>
              {!discount && <p></p>}
              {discount && 
              <Fragment>
              <Input
              onChange={discountPriceChangeHandler}
              label="Τιμή με έκπτωση:"
              isPrice="true"
              input={{
                id: 1,
                type: 'text',
                value: discountPrice
              }} />
              <p>Σύνολο με έκπτωση: {totalPriceDiscount}€</p>
              </Fragment>}
            </div>}
            <div className={classes.textarea}>
            <label htmlFor="sku">Σημειώσεις: </label>
            <textarea id="sku" rows="2" value={notes} onChange={notesChangeHandler}></textarea>
            </div>
          </Fragment>
        }
        <Input
      onChange={quantityChangeHandler}
      label="Ποσότητα:"
      input={{
        id: 1,
        type: 'text',
        value: quantity
      }} />
      <div className={classes.actions}>
        <Button className={classes[`button--alt`]} onClick={props.onClose}>Πίσω</Button>
        <Button className={`${classes[`button--alt`]} ${buttonDisabled? classes.buttonLoad:''}`} disabled={buttonDisabled} onClick={handleSaveClick} >{buttonDisabled?<FontAwesomeIcon icon={faCircleNotch} spin />:'Αποθήκευση'}</Button>
      </div>
    </Modal>
  )
}

export default Fabrics;
