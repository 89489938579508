import React, {Fragment, useEffect, useState} from 'react';
import FormData from 'form-data';
import Card from '../UI/Card';
import axios from 'axios';
import classes from './SingleXMLItem.module.css';

import {useCookies} from 'react-cookie';
import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faSave} from '@fortawesome/free-solid-svg-icons'

const SingleXMLItem = (props) =>{
	const [editClick,setEditClick] = useState(true);
  	const [changedDiscount, setChangedDiscount] = useState(props.discount);
  	const [changed, setChanged] = useState(props.changed);
  	const [priceWithDiscount, setPriceWithDiscount] = useState(Math.round(props.priceWithDiscount.replace(/,/g, '.')*100)/100)

  	const pencil = <FontAwesomeIcon icon={faPencilAlt} />
	const save = <FontAwesomeIcon icon={faSave} />

	const handleEditClick = () =>{
    	setEditClick(false);
  	}	
	const handleSaveClick = () =>{
		let data = new FormData();
		data.append('apikey', '9g82n398nqf98nf9q839g8h@93w8nfa!');
		data.append('productCode', props.Code);
		data.append('discount', changedDiscount);
		data.append('barcodePrice', props.BarCodePrice);
		
		let config = {
		  method: 'post',
		  maxBodyLength: Infinity,
		  url: 'https://e-interior.gr/save.php?call='+props.call,
		  headers: { 
		    'Content-Type': 'application/x-www-form-urlencoded'
		  },
		  data : data
		};

		axios.request(config)
		.then((response) => {
		  console.log(response);
		  setEditClick(true);
		  setChanged("1")
		  setPriceWithDiscount(Math.round((props.BarCodePrice-(props.BarCodePrice*changedDiscount))*100)/100)
		})
		.catch((error) => {
		  console.log(error);
		});

	   	setEditClick(true);
	}

	const handleChange = (event) =>{
		let value = event.target.value.toString();
    	value = value.replace(/,/g,".");
	   setChangedDiscount(value);
	}
	return(
		<Fragment>
			<tr key={props.id} className={changed==="1"?classes.changed:""}>
		        <td>{props.Code}</td>
		        <td>{props.Description}</td>
		        <td>{props.BarCodePrice}</td>
		        {editClick && <td>{changedDiscount*100+'%'}</td>}

		        {!editClick && <td><input onChange={handleChange} value={changedDiscount}/></td>}
		        <td>{priceWithDiscount}</td>
		        {editClick &&<td title="Επεξεργασία" onClick={handleEditClick}>{pencil}</td>}
				{!editClick && <td title="Επεξεργασία" onClick={handleSaveClick}>{save}</td>}
		   </tr>
		</Fragment>
	    )
}
export default SingleXMLItem;