import React,{useState,useEffect,Fragment} from 'react';
import {useCookies} from 'react-cookie';
import { Navigate, Route, Routes, useNavigate,Outlet, Link  } from "react-router-dom";
import Button from '../UI/Button';
import classes from './Turnover.module.css';
import axios from 'axios';

const Turnover = (props) =>{
	const navigate = useNavigate();
	const [cookies, setCookies, removeCookies] = useCookies(['user']);
	const [manufacturers, setManufacturers]=useState([]);
  	const [manufacturerSelection, setManufacturerSelection] = useState("all");
  	const [manufacturerDropdown, setManufacturerDropdown] = useState('');
  	const [fromDate, setFromDate] = useState(new Date().toISOString().slice(0, 10));
  	const [toDate, setToDate] = useState(new Date().toISOString().slice(0, 10))
  	const [stats, setStats] = useState();
  	const [isLoaded, setIsLoaded] = useState(false);


useEffect(() => {
    setIsLoaded(true);

  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: 'https://api.orders.e-interior.gr/api/verify',
    headers: { 
      'Accept': 'application/json', 
      'Authorization': 'Bearer '+cookies.Token, 
    },
  };

  axios.request(config)
  .then((response) => {
    if (response.data.message!=="Success"){
    	removeCookies('Name', { path: '/' });
    	removeCookies('Token', { path: '/' });
      navigate("/user-login");
    };
  })
  .catch((error) => {
  	removeCookies('Name', { path: '/' });
  	removeCookies('Token', { path: '/' });
    navigate("/user-login");
  });

}, []);

	useEffect(() => {
    	
    	let config = {
		  method: 'post',
		  maxBodyLength: Infinity,
		  url: 'https://api.orders.e-interior.gr/api/tziros/manufacturer-list',
		  headers: { 
		    'Accept': 'application/json', 
		    'Authorization': 'Bearer '+cookies.Token, 
		  }}
	  	axios.request(config)
			.then((response) => {
				setManufacturers(response.data)
			})
			.catch((error) => {
				if (error.response.data.message==="Unauthenticated."){
        removeCookies('Name', { path: '/' });
        removeCookies('Token', { path: '/' });
        navigate("/user-login");
      }
			  console.log(error);
			});
	}, [isLoaded]);

	const handleManufacturerChange = (e) =>{
		setManufacturerSelection(e.target.value)
	}

	const handleFromDateChange = (e) =>{
		setFromDate(e.target.value);
	}

	const handleToDateChange = (e) =>{
		setToDate(e.target.value);
	}

	const handleSearchClick = (e) =>{
		console.log(manufacturerSelection+" "+fromDate+" "+toDate+" ")

		let data = new FormData();
		data.append('manufacturer', manufacturerSelection);
		data.append('start', fromDate.replace(/-/g, '/').toString());
		data.append('end', toDate.replace(/-/g, '/').toString());

		let config = {
		  method: 'post',
		  maxBodyLength: Infinity,
		  url: 'https://api.orders.e-interior.gr/api/tziros',
		  headers: { 
		    'Accept': 'application/json', 
		    'Authorization': 'Bearer 5|X4kcu2RRJQOFjOZxtMb16xzk5dM1hgSTKeAPS9Lp', 
		  },
		  data : data
			};

		axios.request(config)
			.then((response) => {
				setStats(response.data);
				console.log(response.data);
			})
			.catch((error) => {
			  	console.log(error);
			});
		}


	return(
		<Fragment>
			<div className={classes.container}>
				<div className={classes.filterList}>
					<span className={classes.filterItem}>
						<select value={manufacturerSelection} onChange={handleManufacturerChange}>
							<option value="all">Manufacturers: All</option>
		              	{Array.isArray(manufacturers) && manufacturers.map((option) => (

		                	<option value={option}>{option}</option>

		              	))}
              			</select>
					</span>
					<span className={classes.filterItem}>Από:<input onChange={handleFromDateChange} value={fromDate} type='date'/></span>
					<span className={classes.filterItem}>Εώς:<input onChange={handleToDateChange} value={toDate} type='date'/></span>
					<span className={`${classes.filterItem} ${classes.search}`} onClick={handleSearchClick}>Αναζήτηση</span>
				</div>
				{stats&&
				<div className={classes.boxGrid}>
					<div className={classes.saleBoxes}>
						<div className={classes.partialBox}>
							<p className={classes.boxTitle}>Ηλεκτρονικό Κατάστημα</p>
							<div className={classes.partialTotals}>
								<div>
									<p>Σύνολο (χωρίς ΦΠΑ): {stats.eshopStats.total_without_tax}€</p>
									<p><strong>Σύνολο: {stats.eshopStats.total_paid}€</strong></p>
								</div>
								<div className={classes.storePercentage}>
									<p><span>{stats.eshopStats.percentage+'%'}</span></p>
								</div>
							</div>
						</div>
						<div className={classes.partialBox}>
							<p className={classes.boxTitle}>Φυσικό Κατάστημα</p>
							<div className={classes.partialTotals}>
								<div>
									<p>Σύνολο (χωρίς ΦΠΑ): {stats.storeStats.total_without_tax}€</p>
									<p><strong>Σύνολο: {stats.storeStats.total_paid}€</strong></p>
								</div>
								<div className={classes.storePercentage}>
									<p><span>{stats.storeStats.percentage+'%'}</span></p>
								</div>
							</div>
						</div>
						<div className={classes.partialBox}>
							<p className={classes.boxTitle}>Τοποθέτηση</p>
							<div className={classes.partialTotals}>
								<div>
									<p>Συνολικό κόστος τοποθέτησης: {stats.installationStats.total_installation}€</p>
									<p>Συνολικό κόστος βοηθού: {stats.installationStats.total_assistant}€</p>
									<p><strong>Κέρδος: {stats.installationStats.profit}€</strong></p>
								</div>
							</div>
						</div>
					</div>
					<div className={classes.totalBox}>
						<p className={classes.boxTitle}>Σύνολο Καταστημάτων</p>
						<div className={classes.total}>
							<p>Σύνολο :</p>
							<p><strong>{stats.totalStats.total_paid} €</strong></p>
						</div>
					</div>
					<div className={classes.manufacturerBox}>
						<p className={classes.boxTitle}>Πωλήσεις Ανα εταιρεία</p>
						<div className={classes.manufacturersList}>
						
						{Object.entries(stats.manufacturersSales).map(([key, value]) => (
					        <div key={key} className={classes.manufacturersListItem}>
					          <p>{key?key:"Χωρίς Κατασκευαστή"}:</p>
					          <p>{value} €</p>
					        </div>
					      ))}

						</div>
					</div>
				</div>
			}
			</div>
		</Fragment>
		)
}
export default Turnover;