import React,{useState,useEffect, Fragment} from 'react';
import {useCookies} from 'react-cookie';
import { Navigate, Route, Routes, useNavigate,Outlet, Link  } from "react-router-dom";
import Modal from '../UI/Modal';
import Input from '../UI/Input';
import Button from '../UI/Button';

import classes from './ClientEdit.module.css';
import axios from 'axios';

const ClientEdit = (props) =>{
  //states
  const navigate = useNavigate();
  const [cookies, setCookies, removeCookies] = useCookies(['user']);
  const [isLoaded, setIsLoaded] = useState(false);
  const [clientInfo, setClientInfo] = useState('');
  const [clientBasicRender, setClientBasicRender] = useState('');
  const [clientInvoiceRender, setClientInvoiceRender] = useState('');
  const [timologioToggle, setTimologioToggle] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
}, []);

  useEffect(() => {
    const config = {
      headers: { 'Authorization': 'Bearer '+cookies.Token}
    };
    axios.post('https://api.orders.e-interior.gr/api/client/'+props.id,{
      key: cookies.Token},
      config
    )
    .then(function (response) {
      console.log(response.data);
      setClientInfo(response.data)
    })
    .catch(function (error) {
      if (error.response.data.message==="Unauthenticated."){
        removeCookies('Name', { path: '/' });
        removeCookies('Token', { path: '/' });
        navigate("/user-login");
      }
      console.log(error);
    });
  },[isLoaded])

  const handleTimologioClick = () => {
    if (timologioToggle === false){
      setTimologioToggle(true);
    }else{
      setTimologioToggle(false);
    }
  };

    function handleFirstNameChange(e) {
        setClientInfo({
          ...clientInfo,
          first_name: e.target.value
        });

  }

  function handleAddressChange(e) {
        setClientInfo({
          ...clientInfo,
          address: e.target.value
        });
  }
  function handleLastNameChange(e) {
        setClientInfo({
          ...clientInfo,
          last_name: e.target.value
        });
  }
  function handleCityChange(e) {
        setClientInfo({
          ...clientInfo,
          city: e.target.value
        });
  }
  function handlePhoneChange(e) {
        setClientInfo({
          ...clientInfo,
          phone: e.target.value
        });
  }
  function handleAreaCodeChange(e) {
        setClientInfo({
          ...clientInfo,
          area_code: e.target.value
        });
  }
  function handleEmailChange(e) {
        setClientInfo({
          ...clientInfo,
          email: e.target.value
        });
  }
  function handleAfmChange(e) {
        setClientInfo({
          ...clientInfo,
          afm: e.target.value
        });
  }
  function handleIndustryChange(e) {
        setClientInfo({
          ...clientInfo,
          industry: e.target.value
        });
  }
  function handleBusinessNameChange(e) {
        setClientInfo({
          ...clientInfo,
          business_name: e.target.value
        });
  }
  function handleDoyChange(e) {
        setClientInfo({
          ...clientInfo,
          doy: e.target.value
        });
  }

  function handleSaveButton(e){
    const config = {
        headers: { 'Authorization': 'Bearer '+cookies.Token}
      };
    axios.post('https://api.orders.e-interior.gr/api/client/update',
        clientInfo,
        config)
      .then(function (response) {
        console.log(response);
        props.onSave({response});
      })
      .catch(function (error) {
        if (error.response.data.message==="Unauthenticated."){
        removeCookies('Name', { path: '/' });
        removeCookies('Token', { path: '/' });
        navigate("/user-login");
      }
        console.log(error);
      });
  }

    useEffect(() => {
    setClientBasicRender(
    <Fragment>
    <div className={classes.grid}>
     <Input onChange={handleFirstNameChange}
     label="Όνομα:*"
     input={{
       id: 1,
       type: 'text',
       value: clientInfo.first_name
     }} />
     <Input onChange={handleLastNameChange}
     label="Επώνυμο:*"
     input={{
       id: 1,
       type: 'text',
       value: clientInfo.last_name
     }} />
     <Input onChange={handlePhoneChange}
     label="Τηλέφωνο:*"
     input={{
       id: 1,
       type: 'text',
       value: clientInfo.phone
     }} />
    <Input onChange={handleAddressChange}
     label="Διεύθυνση:"
     input={{
       id: 1,
       type: 'text',
       value: clientInfo.address
     }} />
     <Input onChange={handleCityChange}
     label="Πόλη:"
     input={{
       id: 1,
       type: 'text',
       value: clientInfo.city
     }} />
     <Input onChange={handleAreaCodeChange}
     label="Τ.Κ.:"
     input={{
       id: 1,
       type: 'text',
       value: clientInfo.area_code
     }} />
     <Input onChange={handleEmailChange}
     label="E-mail:"
     input={{
       id: 1,
       type: 'text',
       value: clientInfo.email
     }} />
    </div>

    </Fragment>);
    setClientInvoiceRender(
    <div className={`${classes.grid} ${classes.timologioForm}`} >
     <Input onChange={handleAfmChange}
     label="ΑΦΜ:"
     input={{
       id: 1,
       type: 'text',
       value: clientInfo.afm
     }} />
     <Input onChange={handleIndustryChange}
     label="Επάγγελμα:"
     input={{
       id: 1,
       type: 'text',
       value: clientInfo.industry
     }} />
     <Input onChange={handleBusinessNameChange}
     label="Επωνυμία:"
     input={{
       id: 1,
       type: 'text',
       value: clientInfo.business_name
     }} />
     <Input onChange={handleDoyChange}
     label="Δ.Ο.Υ:"
     input={{
       id: 1,
       type: 'text',
       value: clientInfo.doy
     }} />
     </div>)

    setTimologioToggle(clientInfo.afm || clientInfo.industry || clientInfo.business_name || clientInfo.doy)
  }, [clientInfo]);


    return(
      <Modal>
        <div className={classes.clientCard}>
        {clientBasicRender}
        </div>
        <div className={classes.clientCard}>
          <div className={classes.timologio}>
            <span>
              <label htmlFor='timologio'>Θέλω τιμολόγιο: </label>
              <input id='timologio' type='checkbox' checked={timologioToggle} onClick={handleTimologioClick}></input>
            </span>
           <span className={classes.divider}></span>
           </div>
         </div>
         <div className={classes.clientCard}>
         {timologioToggle && clientInvoiceRender}
         </div>
         <div className={`${classes.ButtonSet}`}>
         <span></span>
         <span className={classes.cancelButton}><Button onClick={props.onClose}>Πίσω</Button></span>
         <span></span>
         <span className={classes.nextButton}><Button onClick={handleSaveButton}>Αποθήκευση</Button></span>
         <span></span>
         </div>
       </Modal>
     )
}
  export default ClientEdit;