import React,{useState,useEffect,Fragment} from 'react';
import Modal from '../UI/Modal';
import classes from './ViewOrderHistory.module.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from '../UI/Button';
import Input from '../UI/Input'
import ClientView from '../Clients/ClientView'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faSave, faEye, faTrash, faCopy } from '@fortawesome/free-solid-svg-icons'
import { Navigate, Route, Routes, useNavigate,Outlet, Link  } from "react-router-dom";
import {useCookies} from 'react-cookie';
import axios from 'axios';


//view products
import FabricView from '../ViewProducts/FabricView'
import CurtainRodView from '../ViewProducts/CurtainRodView'
import PersidesView from '../ViewProducts/PersidesView'
import RollerView from '../ViewProducts/RollerView'
import XaliView from '../ViewProducts/XaliView'
import RomanView from '../ViewProducts/RomanView'
import CustomProductView from '../ViewProducts/CustomProductView'

//edit products
import CurtainRodEdit from '../EditProducts/CurtainRodEdit'
import FabricEdit from '../EditProducts/FabricEdit'
import PersidesEdit from '../EditProducts/PersidesEdit'
import RollerEdit from '../EditProducts/RollerEdit'
import RomanEdit from '../EditProducts/RomanEdit'
import XaliEdit from '../EditProducts/XaliEdit'
import CustomProductEdit from '../EditProducts/CustomProductEdit';

const view = <FontAwesomeIcon icon={faEye} />
const pencil = <FontAwesomeIcon icon={faPencilAlt} />
const copy = <FontAwesomeIcon icon={faCopy} />
const trash = <FontAwesomeIcon icon={faTrash} />

const ViewOrderInProgress = (props) =>{
  const navigate = useNavigate();
	const [cookies, setCookies, removeCookies] = useCookies(['user']);
	const [order,setOrder]=useState('');
	const [orderExtras,setOrderExtras]=useState({paradosi:"",metrisi:"",topothetisi:"",rafis:"",sideroma:""});
	const [orderPayment,setOrderPayment]=useState({paymentType:"",metrita:"",karta:"",synolo:"",prokatavoli:""});
  const [orderDetailed, setOrderDetailed]=useState([{}])
  const [balance, setBalance]=useState(0)
	const [viewModal, setViewModal]=useState('');
	const [editModal, setEditModal]=useState('');
	const [updatedProduct, setUpdatedProduct]=useState('');
  const [makingPayment, setMakingPayment]=useState(false);
  const [paymentSelection, setPaymentSelection]=useState('metrita');
  const [paymentInput, setPaymentInput]=useState('');
  const [totalProfit, setTotalProfit]=useState(0); 
  const [client, setClient]=useState(""); 

  const [clientModal, setClientModal]=useState('');

  const paymentSelectionHandler = (event)=>{
    setPaymentSelection(event.target.value);

  }
  const paymentInputChangeHandler = (event)=>{
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    setPaymentInput(value);
  }

  const handleClientView = () =>{
      setClientModal(<ClientView id={order.client_id} onClose={clientViewCloseHandler}/>)
    }
    const clientViewCloseHandler = () =>{
      setClientModal('')
    }
  useEffect(() => {
    if (order){
    const config = {
      headers: { 'Authorization': 'Bearer '+cookies.Token}
    };
    axios.post('https://api.orders.e-interior.gr/api/client/'+order.client_id,{
      key: cookies.Token},
      config
    )
    .then(function (response) {
      setClient(response.data)
    })
    .catch(function (error) {
      if (error.response.data.message==="Unauthenticated."){
          removeCookies('Name', { path: '/' });
          removeCookies('Token', { path: '/' });
          navigate("/user-login");
        }
      console.log(error);
    })
  }
  },[order])
	useEffect(() => {
    const config = {
      headers: { 'Authorization': 'Bearer '+cookies.Token}
    };
    //get all orders 
    axios.post('https://api.orders.e-interior.gr/api/order/'+props.id,{
      key: cookies.Token},
      config
    )
    .then(function (response) {
      setOrder(response.data)
      setOrderExtras(JSON.parse(response.data.extras));
      setOrderPayment(JSON.parse(response.data.payment));
      let details = JSON.parse(response.data.payment).detailed;
      setOrderDetailed(JSON.parse(details));
    })
    .catch(function (error) {
      if (error.response.data.message==="Unauthenticated."){
          removeCookies('Name', { path: '/' });
          removeCookies('Token', { path: '/' });
          navigate("/user-login");
        }
      console.log(error);
  	})
    },[editModal,updatedProduct,makingPayment]);

const handleSubmitOrder = () =>{
  props.returnedInProgress({id:props.id});

}
 const viewClickHandler = (id,productType) =>{
  	console.log(id+productType);
    if (productType.includes("Ύφασμα")){
      setViewModal(<FabricView id={id} client_name = {client.first_name+' '+client.last_name} onClose={viewCloseHandler}/>)
    }else if (productType.includes("Κουρτινόξυλο")){
      setViewModal(<CurtainRodView id={id} client_name = {client.first_name+' '+client.last_name} onClose={viewCloseHandler}/>)
    }else if (productType.includes("Περσίδες")){
      setViewModal(<PersidesView id={id} client_name = {client.first_name+' '+client.last_name} onClose={viewCloseHandler}/>)
    }else if (productType.includes("roller")){
      setViewModal(<RollerView id={id} client_name = {client.first_name+' '+client.last_name} onClose={viewCloseHandler}/>)
    }else if (productType.includes("χαλί")||productType.includes("Μοκέτα")||productType.includes("Πλαστικό")){
      setViewModal(<XaliView id={id} client_name = {client.first_name+' '+client.last_name} onClose={viewCloseHandler}/>)
    }else if (productType.includes('Ρόμαν')||productType.includes('Ψευτορόμαν')){
      setViewModal(<RomanView id={id} client_name = {client.first_name+' '+client.last_name} onClose={viewCloseHandler}/>)
    }else if (productType.includes('Άλλο είδος')){
      setViewModal(<CustomProductView id={id} client_name = {client.first_name+' '+client.last_name} onClose={viewCloseHandler}/>)
    }
  }
  const viewCloseHandler = ()=>{
    setViewModal('');
  }

  const newPaymentHandler = ()=>{
    setMakingPayment(true);
  }
  const cancelPaymentHandler = ()=>{
    setMakingPayment(false);
  }
  const savePaymentHandler = ()=>{
    if (paymentInput>0){
      let detailed = orderDetailed;
      let currDate = new Date();
      detailed.push({
        type:"pliromi",
        amount:paymentInput, 
        how:paymentSelection,
        date: currDate
        })
      
      const config = {
        headers: { 'Authorization': 'Bearer '+cookies.Token}
        };
        axios.post('https://api.orders.e-interior.gr/api/order/update',{
          key: cookies.Token,
          order_id: props.id,
          payment:JSON.stringify({...orderPayment, detailed:JSON.stringify(detailed)})

          },
          config)
        .then(function (response) {
          setMakingPayment(false);
          setPaymentInput('');
          setPaymentSelection('metrita');
        })
        .catch(function (error) {
          if (error.response.data.message==="Unauthenticated."){
          removeCookies('Name', { path: '/' });
          removeCookies('Token', { path: '/' });
          navigate("/user-login");
        }
          console.log(error);
        });
      }
    }
    useEffect(()=>{

    let expensesCalc = 0;
    if (order.products){
      for (let product of order.products){
        if (product.status!=='deleted'){
          let wholesale = JSON.parse(product.expenses).wholesale?Number(JSON.parse(product.expenses).wholesale):0;
          let shipping = JSON.parse(product.expenses).shipping?Number(JSON.parse(product.expenses).shipping):0;
          let assistant = JSON.parse(product.expenses).assistant? Number(JSON.parse(product.expenses).assistant):0;
          let sewing = JSON.parse(product.expenses).sewing?Number(JSON.parse(product.expenses).sewing):0;
          expensesCalc += wholesale+shipping+sewing+assistant;

        }
    }
    console.log('updated');
    setTotalProfit(Math.round((orderPayment.synolo-expensesCalc)*100)/100);
    }
    
  },[order,orderPayment])

    useEffect(()=>{
      // console.log(totalProfit)
    },[totalProfit])

   const editClickHandler = (id,productType) =>{
      if (productType.includes("Ύφασμα")){
      setEditModal(<FabricEdit id={id} onClose={editCloseHandler} onSave={editCloseHandler}/>)
	    }else if (productType.includes("Κουρτινόξυλο")){
	      setEditModal(<CurtainRodEdit id={id} onClose={editCloseHandler} onSave={editCloseHandler}/>)
	    }else if (productType.includes("Περσίδες")){
	      setEditModal(<PersidesEdit id={id} onClose={editCloseHandler} onSave={editCloseHandler}/>)
	    }else if (productType.includes("roller")){
	      setEditModal(<RollerEdit id={id} onClose={editCloseHandler} onSave={editCloseHandler}/>)
	    }else if (productType.includes("χαλί")||productType.includes("Μοκέτα")||productType.includes("Πλαστικό")){
	      setEditModal(<XaliEdit id={id} onClose={editCloseHandler} onSave={editCloseHandler}/>)
	    }else if (productType.includes('Ρόμαν')||productType.includes('Ψευτορόμαν')){
	      setEditModal(<RomanEdit id={id} onClose={editCloseHandler} onSave={editCloseHandler}/>)
    	}else if (productType.includes('Άλλο είδος')){
      setEditModal(<CustomProductEdit id={id} onClose={editCloseHandler} onSave={editCloseHandler}/>)
    	}
  } 
  const editCloseHandler = ()=>{
  	setEditModal('');
  }
  const copyClickHandler = (id) =>{
        const config = {
        headers: { 'Authorization': 'Bearer '+cookies.Token}
      };
      axios.post('https://api.orders.e-interior.gr/api/product/'+id,{
        key: cookies.Token},
        config
      )
      .then(function (response) {
        console.log(response.data);
        let productInfo = response.data;
        let productOptions = response.data.product_options;
        axios.post('https://api.orders.e-interior.gr/api/product/create',{
        key: cookies.Token,
        order_id: props.id,
        product_type: productInfo.product_type,
        manufacturer: productInfo.manufacturer,
        code: productInfo.code,
        product_options: productOptions,
        status: "submitted",
        ordered_at: null
        },
        config
      )
        .then(function (response) {
          setUpdatedProduct(response.data)

        })
        .catch(function (error) {
          console.log(error);
        });
      })
      .catch(function (error) {
        if (error.response.data.message==="Unauthenticated."){
          removeCookies('Name', { path: '/' });
          removeCookies('Token', { path: '/' });
          navigate("/user-login");
        }
        console.log(error);
      });
  }
  const deleteClickHandler = (id) =>{
        const config = {
          headers: { 'Authorization': 'Bearer '+cookies.Token}
        };

        axios.post('https://api.orders.e-interior.gr/api/product/update',{
          key: cookies.Token,
          order_id: cookies.OrderId,
          product_id: id,
          status: "deleted",
          },
          config
        )
        .then(function (response) {
          console.log(response.data);
          setUpdatedProduct(response.data)
        })
        .catch(function (error) {
          if (error.response.data.message==="Unauthenticated."){
          removeCookies('Name', { path: '/' });
          removeCookies('Token', { path: '/' });
          navigate("/user-login");
        }
          console.log(error);
        });
    }

  useEffect(()=>{
    let paymentSum=0;
    for (const o of orderDetailed){
      paymentSum+=Number(o.amount);
      // console.log(o.amount);
    }

    setBalance(Number(orderPayment.synolo)-paymentSum)
  },[orderExtras,orderPayment,orderDetailed,makingPayment])
	return(
		<Fragment>
    {clientModal}
		{viewModal}
		{editModal}
			<button className={classes.close} onClick={props.onClose}>Πίσω</button>
      <div className={classes.submitOrder}>
        <Button className={`${classes.submitOrderButton} ${classes.notPrintable}`} onClick={handleSubmitOrder}>Επαναφορά</Button>
      </div>
			<h2>Παραγγελία</h2>
			
			<div className={classes.grid}>
			<div className={classes.left}>
				<p>Πελάτης: {props.fullName} <span onClick={handleClientView}>{view}</span></p>
        {orderExtras.paradosi && <p>Παράδοση: {orderExtras.paradosi}€</p>}
        {orderExtras.metrisi && <p>Μέτρηση: {orderExtras.metrisi}€</p>}
        {orderExtras.topothetisi>0 && <p>Τοποθέτηση: {orderExtras.topothetisi}€</p>}
        {orderExtras.rafis && <p>Ραφή: {orderExtras.rafis}€</p>}
        {orderExtras.sideroma && <p>Σιδέρωμα: {orderExtras.sideroma}€</p>}
        {orderPayment.synolo&& <p>Σύνολο: {orderPayment.synolo}€</p>}
        <p className={classes.ypoloipo}>Υπόλοιπο: {balance}€</p>
        {orderDetailed && 
        <Fragment>
          {orderDetailed.map((result) =>{
            if (result.amount){
              let formattedDate = '';
              if (result.date.length>0){
                const date= new Date(result.date);
                formattedDate = `${date.getUTCDate()}/${date.getUTCMonth() + 1}/${date.getUTCFullYear()}`
              }
            return(
              <p style={{ textAlign: 'left' }}>{formattedDate} - {result.type==="prokatavoli"?"Προκαταβολή":"Πληρωμή"} με {result.how==="metrita"?"μετρητά":result.how==="karta"?"κάρτα":"κατάθεση"}: {result.amount}€</p>)
          }
          })}
        </Fragment>}
        {!makingPayment &&<Button className={classes.newPayment} onClick={newPaymentHandler}>Νέα Πληρωμή</Button>}
        {makingPayment &&
        <Fragment>
        <p>
         <select id="paymentSelection" name="paymentSelection" onChange={paymentSelectionHandler}>
          <option value="metrita">Μετρητά</option>
          <option value="karta">Κάρτα</option>
          <option value="katathesi">Κατάθεση</option>
        </select> </p>
        <p><Input onChange={paymentInputChangeHandler}
          label="Νέα Πληρωμή:"
          vert="true"
          isPrice="true"
          input={{
            id: 9,
            type:'text',
            value: paymentInput
          }} /></p>
          
          <div>
            <Button className={classes.cancelPayment} onClick={cancelPaymentHandler}>Ακύρωση</Button>
            <Button className={classes.newPayment} onClick={savePaymentHandler}>Αποθήκευση</Button>
          </div>
        </Fragment>
        }
			</div>
			<div className={classes.right}>
			<table className="table table-hover">
				<thead>
	        <tr>
	          <th>Κωδικός/Περιγραφή</th>
	          <th>Είδος</th>
	          <th>Εταιρεία</th>
	          <th>Τιμή</th>
	          <th>Ενέργειες</th>
	        </tr>
	      </thead>
        <tbody>
        {order.products && order.products.map((result) =>{
        	return(
		        <tr className={`${classes.productList} ${result.status==='deleted' && classes.deleted}`}>
              <td>{result.code}</td>
              <td>{result.product_type}</td>
              <td>{result.manufacturer}</td>
              {!(JSON.parse(result.product_options).quantity>0)&&
              <td>{JSON.parse(result.product_options).discountPrice ?
               JSON.parse(result.product_options).discountPrice:JSON.parse(result.product_options).totalPrice}€
               </td>}
              {JSON.parse(result.product_options).quantity>0&&
              <td className={classes.priceQuantity}><p className={`${JSON.parse(result.product_options).quantity>1 && classes.singleItemPrice} ${(JSON.parse(result.product_options).discountPrice) && classes.strikeThrough}`}>{Math.round(JSON.parse(result.product_options).totalPrice/JSON.parse(result.product_options).quantity*100)/100}€{JSON.parse(result.product_options).quantity>1 &&" x "+JSON.parse(result.product_options).quantity}</p> 
               {JSON.parse(result.product_options).quantity>1 && <p className={JSON.parse(result.product_options).discountPrice && classes.strikeThrough}>
                {JSON.parse(result.product_options).totalPrice}€</p>}
               {(JSON.parse(result.product_options).discountPrice||JSON.parse(result.product_options).totalPriceDiscount) && 
               <p>{JSON.parse(result.product_options).totalPriceDiscount?JSON.parse(result.product_options).totalPriceDiscount:JSON.parse(result.product_options).discountPrice}€</p>}
               </td>}
              <td><p><span style={{marginRight:1 + 'em'}} onClick={()=>viewClickHandler(result.id,result.product_type)}>{view}</span>
                <span onClick={()=>editClickHandler(result.id,result.product_type)}>{pencil}</span></p>
                <p><span style={{marginRight:1 + 'em'}} onClick={()=>copyClickHandler(result.id,result.product_type)}>{copy}</span>
                <span onClick={()=>deleteClickHandler(result.id)}>{trash}</span></p>
              </td>
            </tr>
        		)
        })}
        </tbody>
      </table>
        <div>
              <p className={classes.profitText}>Κέρδος: {totalProfit}€</p>
        </div>
			</div>
			</div>
		</Fragment>
		)
}
export default ViewOrderInProgress;