import React, {useState,useEffect} from 'react';
import Card from '../UI/Card';
import classes from './AtTailor.module.css'
import SingleAtTailor from './SingleAtTailor'
import { Navigate, Route, Routes, useNavigate,Outlet, Link  } from "react-router-dom";
import {useCookies} from 'react-cookie';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPencilAlt } from '@fortawesome/free-solid-svg-icons';

const AtTailor = () =>{

  const navigate = useNavigate();
  const [cookies, setCookies, removeCookies] = useCookies(['user']);
  const [products,setProducts] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [productUpdate, setProductUpdate] = useState(false);
  const [companies, setCompanies] = useState('');
  const [currentClients, setCurrentClients] = useState('');
  const [clientSelection, setClientSelection] = useState('0')
  const [currentTailors, setCurrentTailors] = useState('');
  const [tailorSelection, setTailorSelection] = useState('0');
  const [showOrdered, setShowOrdered] = useState(false)
   
  useEffect(() => {
    if (!cookies.Token) {
      navigate("/user-login");
    }
  });
  useEffect(() => {
    setIsLoaded(true);
}, []);
  useEffect(() => {
    if (isLoaded){
      const config = {
          headers: { Authorization: "Bearer 5|X4kcu2RRJQOFjOZxtMb16xzk5dM1hgSTKeAPS9Lp"}
        };
        axios.post('https://api.orders.e-interior.gr/api/general/init',{
          key: cookies.Token},
          config
        )
        .then(function (response) {
          setCompanies(response.data[0].companies.sort());
    
        })
        .catch(function (error) {
          console.log(error);
        });
    
        axios.post('https://api.orders.e-interior.gr/api/rafeioProducts',{
          key: cookies.Token},
          config
        )
        .then(function (response) {
          console.log(response.data)
          setProducts(response.data.sort(function(a,b) {
            if (a.order_id < b.order_id){
              return -1;
            }else if(a.order_id > b.order_id){
              return 1;
            }else{
              return 0;
            }
          }))
        })
        .catch(function (error) {
          console.log(error);
        });}
  },[isLoaded, productUpdate, showOrdered])

  useEffect(()=>{

    setCurrentClients([...new Set(products.map(item => item.clientName))]);
    setCurrentTailors([...new Set(products.map(item => item.last_status.find(item => item.status === "rafeio").note))]);
  },[products])


  const clientSelectionHandler = (event) =>{
    setClientSelection(event.target.value);
  }
  const tailorSelectionHandler = (event) =>{
    setTailorSelection(event.target.value);
  }
  const handleOrdered = ()=>{
    setProductUpdate(!productUpdate);
  }



  return(
    <Card className={classes.ordersCard}>
    <h2>Στο Ραφείο</h2>

    <table>
      <thead>
        <tr>
          <th>#</th>
          <th><select id="client" name="client" onChange={clientSelectionHandler} value={clientSelection}>
            <option value="0"> --- Πελάτης --- </option>
            {currentClients && currentClients.map((key)=>{
              return(
                <option value={key}> {key}</option>
              )
            }
          )}
          </select></th>
          <th>Προϊόν</th>
          <th>Τιμή Ραφής</th>
          <th>Μεταφορικά</th>
          <th><select id="tailor" name="tailor" onChange={tailorSelectionHandler} value={tailorSelection}>
            <option value="0"> --- Ον. Ραφείου --- </option>
            {currentTailors && currentTailors.map((key)=>{
              return(
                <option value={key}> {key}</option>
              )
            }
          )}
          </select></th>
          <th>Ημ. παράδοσης στο ραφείο</th>
          <th></th>

        </tr>
      </thead>
      <tbody>
      {products.map(({id,order_id,expenses,clientName, vip, code,product_type,last_status,status_updated_at, product_options})=>{
        const findTailor = last_status.find(item => item.status === "rafeio");
        if((clientSelection==="0"&&tailorSelection==="0")||
          (clientSelection===clientName&&tailorSelection==="0")||
          (clientSelection==="0"&&tailorSelection===findTailor.note)||
          (clientSelection===clientName&&tailorSelection===last_status[0].note)){
            return(
              <SingleAtTailor
              id={id}
              order_id={order_id}
              quantity={JSON.parse(product_options).quantity}
              expenses = {expenses}
              sewingPrice = {JSON.parse(expenses).sewing}
              key={id}
              clientName={clientName}
              vip={vip}
              status_updated_at={last_status[0].updated_at.replace("T", " ").split('.')[0]}
              tailorName={findTailor.note}
              statusId = {findTailor.id}
              code={code} 
              product_type={product_type}
              onOrdered={handleOrdered}/>
              )}
        }
      )}
      </tbody> 
    </table>
    </Card>
  )
}
export default AtTailor;
