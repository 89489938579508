import {Fragment,useState} from 'react';
import ReactDOM from 'react-dom';
import classes from './Modal.module.css';

let importedClass="";
const importClass = (x) =>{
  importedClass=x;
}


const Backdrop = props => {
  return(
    <div className={classes.backdrop} onClick={props.onClose}/>
  );
};
const ModalOverlay = props =>{
  return(
    <div className={`${classes.modal} ${importedClass}`}>
      <div className={classes.content}>{props.children}</div>
    </div>
  )
};
const portalElement = document.getElementById('overlays');
const Modal = props => {
  importClass(props.className);
  return (<Fragment>
        {ReactDOM.createPortal(<Backdrop onClose={props.onClose} />, portalElement)}
        {ReactDOM.createPortal(<ModalOverlay >{props.children}</ModalOverlay>,portalElement)}
        </Fragment>)

};

export default Modal;
