import React,{Fragment,useState,useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faSave, faEye, faArrowRotateLeft} from '@fortawesome/free-solid-svg-icons'
import {useCookies} from 'react-cookie';
import ViewOrderInProgress from './ViewOrderHistory'
import classes from './SingleOrderHistory.module.css'
import axios from 'axios';

const pencil = <FontAwesomeIcon icon={faPencilAlt} />
const save = <FontAwesomeIcon icon={faSave} />
const view = <FontAwesomeIcon icon={faEye} />
const returnArrow = <FontAwesomeIcon icon={faArrowRotateLeft} />


const SingleOrderInProgress = (props) =>{
	const [status,setStatus] = useState(props.status);
	const [statusText,setStatusText] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [cookies] = useCookies(['user']);
  const [statuses, setStatuses] = useState({});
  const [totalProfit, setTotalProfit] = useState(0);



  useEffect(()=>{
    if (props.hasInstallation){
    setStatuses({"submitted":"Καταχωρήθηκε","processing":"Σε επεξεργασία", "to_be_installed":"Τοποθέτηση", "completed":"Ολοκληρωμένη","Canceled":"Ακυρωμένη"})
  }else{
    setStatuses({"submitted":"Καταχωρήθηκε","processing":"Σε επεξεργασία", "completed":"Ολοκληρωμένη","canceled":"Ακυρωμένη"})
  }
  },[])

  const viewClick = () =>{
    setModalShow(true);
    props.modalShow({modal:true, id:props.id,fullName:props.fullName});
  }

useEffect(()=>{
      if (status==="not_submitted_yet"){
        setStatusText('Δημιουργήθηκε');
      }else if(status==="submitted"){
        setStatusText('Καταχωρήθηκε');
      }else if(status==="processing"){
        setStatusText('Σε επεξεργασία');
      }else if (status==="to_be_installed"){
        setStatusText('Τοποθέτηση');
      }else if(status==="completed"){
        setStatusText('Ολοκληρωμένη');
      }else if(status==="canceled"){
        setStatusText("Ακυρωμένη");
      }else{
        setStatusText(status);
      }
  },[status])

const handleStatusChange = (event) =>{
	setStatus(event.target.value);
}

useEffect(()=>{
    let expensesCalc = 0;
    if (props.products){
      for (let product of props.products){
        if (product.status!=='deleted'){
          let wholesale = Number(JSON.parse(product.expenses).wholesale);
          let shipping = Number(JSON.parse(product.expenses).shipping);
          let sewing = Number(JSON.parse(product.expenses).sewing);
          expensesCalc += wholesale+shipping+sewing;
        }
    }
  
    setTotalProfit(Math.round((props.totalPrice-expensesCalc)*100)/100);
    console.log(Math.round((props.totalPrice-expensesCalc)*100)/100)
    }
    
  },[props.showProfit,props.products])
const checkClick = () =>{
    
  }

const handleReturnButton = (event) =>{
  props.returnedInProgress({id:props.id});

}
  return(
      <Fragment>
      {!(props.showProfit&&statusText==="Ακυρωμένη")&&
        <tr className={`${classes.orderRow} ${props.showProfit && totalProfit > 0 ? classes.greenRow : ''} ${props.showProfit && totalProfit < 0 ? classes.redRow : ''}`} >
            <td onClick={viewClick}>{props.id}</td>
            <td onClick={viewClick}>{props.fullName}</td>
            <td onClick={viewClick}>{props.totalProducts}</td>
            <td onClick={viewClick}>{props.totalPrice}€</td>
            <td onClick={viewClick}>{props.installPrice}€</td>
          	<td onClick={viewClick}>{statusText}</td>
            <td onClick={viewClick}>{props.created_at.split('T')[0]}</td>
            {!props.showProfit&&<td title="Επαναφορά" onClick={handleReturnButton}>{returnArrow}</td>}
            {props.showProfit&&<td onClick={viewClick}>{totalProfit}€</td>}
        </tr>}

      </Fragment>)
}


export default SingleOrderInProgress