import React,{Fragment, useState, useEffect} from 'react';
import {useCookies} from 'react-cookie';
import { Link, Navigate, useNavigate, useLocation} from "react-router-dom";
import Button from '../UI/Button';
import Input from '../UI/Input';
import classes from './NewOrderClient.module.css';
import NewOrderComplete from './NewOrderComplete'
import ClientAlreadyExists from './ClientAlreadyExists'
import 'bootstrap-select/dist/css/bootstrap-select.min.css';
import 'bootstrap-select/dist/js/bootstrap-select.min.js';
import axios from 'axios';
import $ from 'jquery';
import { Typeahead } from 'react-bootstrap-typeahead'; 



const NewOrderClient = (props) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const navigate = useNavigate();
  const [clientExists, setClientExists] = useState('');
  const [completeModal, setCompleteModal] = useState(false);
  const [timologioToggle, setTimologioToggle] = useState(false);
  const [vipToggle, setVipToggle] = useState(false);
  const [currentId, setCurrentId]=useState('');
  const [currentClient, setCurrentClient]=useState('');
  const [cookies, setCookies, removeCookies] = useCookies(['user']);
  const [clientList, setClientList] = useState(['']);
  const [selectionId, setSelectionId] = useState('-1');
  const [singleSelections, setSingleSelections] = useState([""]);
  const [missingRequired, setMissingRequired] = useState('');
  const [clientBasicRender, setClientBasicRender] = useState('');
  const [clientInvoiceRender, setClientInvoiceRender] = useState('');
  const [clientInfo, setClientInfo] = useState({
    client_id: "0",
    first_name: "",
    last_name: "",
    email: "",
    address: "",
    phone: "",
    city: "",
    area_code: "",
    vip:0,
    afm: "",
    industry: "",
    business_name: "",
    doy: ""
      })
  const [clientSelect, setClientSelect] = useState('');
  //cookie for client id
  const [clientCookie, setClientCookie,removeClientCookie] = useCookies (['client']);
  const [options,setOptions]=useState([{id:'',label:''}])
 
  const [orderExtras, setOrderExtras]=useState('');
  const [orderPayment, setOrderPayment]=useState('');


const location = useLocation()
const { totalCost } = location.state;
const { installPrice } = location.state;
const { initPrice } = location.state;
const { deliver } = location.state;
const { measure } = location.state;
const { sew } = location.state;
const { iron } = location.state;
const { note } = location.state;
const { advancePay } = location.state;

useEffect(()=>{
  // const clientsFunction = () =>{
    const config = {
      headers: { 'Authorization': 'Bearer '+cookies.Token}
    };
    axios.post('https://api.orders.e-interior.gr/api/clients',{
      key: cookies.Token},
      config
    )
    .then(function (response) {
      console.log(response.data);
      setClientList(response.data);

    })
    .catch(function (error) {
      if (error.response.data.message==="Unauthenticated."){
          removeCookies('Name', { path: '/' });
          removeCookies('Token', { path: '/' });
          navigate("/user-login");
        }
      console.log(error);
    });

    
    axios.post('https://api.orders.e-interior.gr/api/order/'+cookies.OrderId,{
      key: cookies.Token},
      config
    )
    .then(function (response) {
      console.log(response.data);
      setOrderExtras(JSON.parse(response.data.extras));
      setOrderPayment(JSON.parse(response.data.payment));
      console.log(JSON.parse(response.data.payment).initialPrice)
    })
    .catch(function (error) {
      console.log(error);
    });

// }
},[]);
useEffect(()=>{

  let filteredClients = clientList.filter(client => client.deleted!==1);
  setOptions(filteredClients.map(({ id, first_name, last_name, phone, vip }) => ({
    id: id,
    label: `${first_name} ${last_name} - ${phone} ${vip===1?'*':''}`,
  })));

}, [clientList])

  const handleSelectChange = (event) =>{
    console.log(event);
    // setSelectionId(event.target.value);

  }

  useEffect(() => {
    console.log(singleSelections[0])
      if (!singleSelections[0]){
        setClientInfo(
        {
          client_id: "0",
          first_name: "",
          last_name: "",
          email: "",
          address: "",
          phone: "",
          city: "",
          area_code: "",
          vip: 0,
          afm: "",
          industry: "",
          business_name: "",
          doy: ""
        }
        )
      }else{
      const index = clientList.findIndex(obj => obj.id === singleSelections[0].id);
      setClientInfo(
        {
          client_id: clientList[index].id ? clientList[index].id:'',
          first_name: clientList[index].first_name ? clientList[index].first_name:'',
          last_name: clientList[index].last_name ? clientList[index].last_name:'',
          email: clientList[index].email ? clientList[index].email:'',
          address: clientList[index].address ? clientList[index].address:'',
          phone: clientList[index].phone ? clientList[index].phone:'',
          city: clientList[index].city ? clientList[index].city:'',
          area_code: clientList[index].area_code ? clientList[index].area_code:'',
          vip: clientList[index].vip ? clientList[index].vip:'',
          afm: clientList[index].afm ? clientList[index].afm:'',
          industry: clientList[index].industry ? clientList[index].industry:'',
          business_name: clientList[index].business_name ? clientList[index].business_name:'',
          doy: clientList[index].doy ? clientList[index].doy:''
            }
        )}
  },[singleSelections]);

  const handleTimologioClick = () => {
    if (timologioToggle === false){
      setTimologioToggle(true);
    }else{
      setTimologioToggle(false);
    }
  };

  const handleVipClick = () => {
    if (clientInfo.vip === false){
      setVipToggle(!clientInfo.vip);
      setClientInfo({
          ...clientInfo,
          vip: true
        });
    }else{
      setVipToggle(!clientInfo.vip);
      setClientInfo({
          ...clientInfo,
          vip: false
        });
    }
  };



  function handleFirstNameChange(e) {
        setClientInfo({
          ...clientInfo,
          first_name: e.target.value
        });

  }

  function handleAddressChange(e) {
        setClientInfo({
          ...clientInfo,
          address: e.target.value
        });
  }
  function handleLastNameChange(e) {
        setClientInfo({
          ...clientInfo,
          last_name: e.target.value
        });
  }
  function handleCityChange(e) {
        setClientInfo({
          ...clientInfo,
          city: e.target.value
        });
  }
  function handlePhoneChange(e) {
        setClientInfo({
          ...clientInfo,
          phone: e.target.value
        });
  }
  function handleAreaCodeChange(e) {
        setClientInfo({
          ...clientInfo,
          area_code: e.target.value
        });
  }
  function handleEmailChange(e) {
        setClientInfo({
          ...clientInfo,
          email: e.target.value
        });
  }
  function handleAfmChange(e) {
        setClientInfo({
          ...clientInfo,
          afm: e.target.value
        });
  }
  function handleIndustryChange(e) {
        setClientInfo({
          ...clientInfo,
          industry: e.target.value
        });
  }
  function handleBusinessNameChange(e) {
        setClientInfo({
          ...clientInfo,
          business_name: e.target.value
        });
  }
  function handleDoyChange(e) {
        setClientInfo({
          ...clientInfo,
          doy: e.target.value
        });
  }


  //blur

  function handlePhoneBlur(e) {
    const input = e.target.value.trim().toLowerCase();
    let pattern = /^\d+.*?(?=\D|$)/;
    const match = clientList.find((client) => client.phone.trim().toLowerCase().match(pattern)[0].replace(/\D/g, '') === input.match(pattern)[0].replace(/\D/g, ''));
    console.log(singleSelections);
    if (match){
      let matchSelection=[{id: match.id, label: match.first_name+' '+match.last_name+' - '+match.phone}];
      setClientExists(<ClientAlreadyExists client={matchSelection} yesClick={handleExistsYes} noClick={handleExistsNo}/>)
    }
    
    // perform any actions you want to do when the focus is lost
  }
  function handleExistsYes(e){
    setSingleSelections(e.match);
    setClientExists('');
  }
  function handleExistsNo(e){
    setClientExists('');
  }
  function handleClearClick(){
    setSingleSelections([]);
    setClientInfo(
        {
          client_id: "0",
          first_name: "",
          last_name: "",
          email: "",
          address: "",
          phone: "",
          city: "",
          area_code: "",
          vip:0,
          afm: "",
          industry: "",
          business_name: "",
          doy: ""
        }
        )
  }

  function handleNextButton(e){
    if (!clientInfo.first_name||!clientInfo.last_name||!clientInfo.phone){
      e.preventDefault();
      setMissingRequired(<p>Missing required fields</p>);
    }else if(!(clientInfo.area_code.length<6)){
      e.preventDefault();
      setMissingRequired(<p>Area code should be 5 digits or smaller</p>);
    }else if(!emailRegex.test(clientInfo.email)&&!clientInfo.email.length==0){
      e.preventDefault();
      setMissingRequired(<p>Email not valid</p>);
    }else if (timologioToggle && (clientInfo.afm.length==0||clientInfo.industry.length==0||clientInfo.business_name.length==0||clientInfo.doy.length==0)){
      e.preventDefault();
      setMissingRequired(<p>Missing required fields</p>);
    }else{
      const config = {
        headers: { 'Authorization': 'Bearer '+cookies.Token}
      };
    if (!(singleSelections[0])){
      axios.post('https://api.orders.e-interior.gr/api/client/create',clientInfo,config)
      .then(function (response) {
        console.log(response.data.client.id);
        setCurrentClient(response.data.client)
        
        const extras = JSON.stringify({
          paradosi: '',
          metrisi: '',
          topothetisi: '',
          rafis: '',
          sideroma: ''
        })
        const payment = JSON.stringify({
          metrita:'',
          karta:'',
          synolo:'',
          prokatavoli:''
        })
        
          axios.post('https://api.orders.e-interior.gr/api/order/update',{

            key: cookies.Token,
            order_id: cookies.OrderId,
            invoice: (timologioToggle ? '1' : '0'),
            client_id: response.data.client.id,
            status:"submitted"
            },
            config
          )
          .then(function (response) {
            console.log(response.data);
            setCompleteModal(true);
            setCurrentId(response.data.order.id)
            removeCookies('OrderId', {path: '/'});
            

          })
          .catch(function (error) {
            console.log(error);
          });
        
      })
      .catch(function (error) {
        if (error.response.data.message==="Unauthenticated."){
          removeCookies('Name', { path: '/' });
          removeCookies('Token', { path: '/' });
          navigate("/user-login");
        }
        console.log(error);
      });
    }else{
      axios.post('https://api.orders.e-interior.gr/api/client/update',
        clientInfo,
        config)
      .then(function (response) {
        console.log(response);
        setCurrentClient({first_name: clientInfo.first_name, last_name: clientInfo.last_name})
        
      })
      .catch(function (error) {
        console.log(error);
      });
      const extras = JSON.stringify({
      paradosi: '',
      metrisi: '',
      topothetisi: '',
      rafis: '',
      sideroma: ''
    })
    const payment = JSON.stringify({
      metrita:'',
      karta:'',
      synolo:'',
      prokatavoli:''
    })

  axios.post('https://api.orders.e-interior.gr/api/order/update',{

        key: cookies.Token,
        order_id: cookies.OrderId,
        invoice: (timologioToggle ? '1' : '0'),
        client_id: clientInfo.client_id,
        status:"submitted"
        },
        config
      )
      .then(function (response) {
        console.log(response.data);
        setCompleteModal(true);
        setCurrentId(response.data.order.id)
        removeCookies('OrderId', {path: '/'});

      })
      .catch(function (error) {
        console.log(error);
      });
   
    }
    
  }
  }
function handleProxeiroButton(e){
    if (!clientInfo.first_name||!clientInfo.last_name||!clientInfo.phone){
      e.preventDefault();
      setMissingRequired(<p>Missing required fields</p>);
    }else if(!(clientInfo.area_code.length<6)){
      e.preventDefault();
      setMissingRequired(<p>Area code should be 5 digits or smaller</p>);
    }else if(!emailRegex.test(clientInfo.email)&&!clientInfo.email.length==0){
      e.preventDefault();
      setMissingRequired(<p>Email not valid</p>);
    }else if (timologioToggle && (clientInfo.afm.length==0||clientInfo.industry.length==0||clientInfo.business_name.length==0||clientInfo.doy.length==0)){
      e.preventDefault();
      setMissingRequired(<p>Missing required fields</p>);
    }else{
      const config = {
        headers: { 'Authorization': 'Bearer '+cookies.Token}
      };
    if (!(singleSelections[0])){
      axios.post('https://api.orders.e-interior.gr/api/client/create',clientInfo,config)
      .then(function (response) {
        console.log(response.data.client.id);
        setCurrentClient(response.data.client)
        
        const extras = JSON.stringify({
          paradosi: '',
          metrisi: '',
          topothetisi: '',
          rafis: '',
          sideroma: ''
        })
        const payment = JSON.stringify({
          metrita:'',
          karta:'',
          synolo:'',
          prokatavoli:''
        })
          axios.post('https://api.orders.e-interior.gr/api/order/update',{

            key: cookies.Token,
            order_id: cookies.OrderId,
            invoice: (timologioToggle ? '1' : '0'),
            client_id: response.data.client.id,
            status:"proxeiro"
            },
            config
          )
          .then(function (response) {
            console.log(response.data);
            setCompleteModal(true);
            setCurrentId(response.data.order.id)
            removeCookies('OrderId', {path: '/'});
            

          })
          .catch(function (error) {
            console.log(error);
          });
        
      })
      .catch(function (error) {
        if (error.response.data.message==="Unauthenticated."){
          removeCookies('Name', { path: '/' });
          removeCookies('Token', { path: '/' });
          navigate("/user-login");
        }
        console.log(error);
      });
    }else{
      axios.post('https://api.orders.e-interior.gr/api/client/update',
        clientInfo,
        config)
      .then(function (response) {
        console.log(response);
        setCurrentClient({first_name: clientInfo.first_name, last_name: clientInfo.last_name})
        
      })
      .catch(function (error) {
        console.log(error);
      });
      const extras = JSON.stringify({
      paradosi: '',
      metrisi: '',
      topothetisi: '',
      rafis: '',
      sideroma: ''
    })
    const payment = JSON.stringify({
      metrita:'',
      karta:'',
      synolo:'',
      prokatavoli:''
    })

    axios.post('https://api.orders.e-interior.gr/api/order/update',{

        key: cookies.Token,
        order_id: cookies.OrderId,
        invoice: (timologioToggle ? '1' : '0'),
        client_id: clientInfo.client_id,
        status:"proxeiro"
        },
        config
      )
      .then(function (response) {
        console.log(response.data);
        setCompleteModal(true);
        setCurrentId(response.data.order.id)
        removeCookies('OrderId', {path: '/'});

      })
      .catch(function (error) {
        console.log(error);
      });
  
    }
    
  }
  }
const handleCheckYes = (e) =>{
    setCompleteModal(false);
    let currentFullName=currentClient.first_name+' '+currentClient.last_name;
    navigate('/in-progress',{state: {currentId: currentId, currentFullName: currentFullName}});
    
  }

const handleCheckNo = (e) =>{
    setCompleteModal(false);
    navigate('/');
  }

  useEffect(() => {
    setClientBasicRender(
    <Fragment>
    <div className={classes.grid}>
     <Input onChange={handleFirstNameChange}
     label="Όνομα:*"
     input={{
       id: 1,
       type: 'text',
       value: clientInfo.first_name
     }} />
     <Input onChange={handleLastNameChange}
     label="Επώνυμο:*"
     input={{
       id: 1,
       type: 'text',
       value: clientInfo.last_name
     }} />
     <Input onChange={handlePhoneChange} onBlur={handlePhoneBlur}
     label="Τηλέφωνο:*"
     input={{
       id: 1,
       type: 'text',
       value: clientInfo.phone
     }} />
    <Input onChange={handleAddressChange}
     label="Διεύθυνση:"
     input={{
       id: 1,
       type: 'text',
       value: clientInfo.address
     }} />
     <Input onChange={handleCityChange}
     label="Πόλη:"
     input={{
       id: 1,
       type: 'text',
       value: clientInfo.city
     }} />
     <Input onChange={handleAreaCodeChange}
     label="Τ.Κ.:"
     input={{
       id: 1,
       type: 'text',
       value: clientInfo.area_code
     }} />
     <Input onChange={handleEmailChange}
     label="E-mail:"
     input={{
       id: 1,
       type: 'text',
       value: clientInfo.email
     }} />
    <div className={classes.vip}>
    <label for="vip">VIP: </label><input id="vip"  checked={clientInfo.vip}  type='checkbox' onClick={handleVipClick}></input>
    </div>
    </div>

    </Fragment>);
    setClientInvoiceRender(
    <div className={`${classes.grid} ${classes.timologioForm}`} >
     <Input onChange={handleAfmChange}
     label="ΑΦΜ:"
     input={{
       id: 1,
       type: 'text',
       value: clientInfo.afm
     }} />
     <Input onChange={handleIndustryChange}
     label="Επάγγελμα:"
     input={{
       id: 1,
       type: 'text',
       value: clientInfo.industry
     }} />
     <Input onChange={handleBusinessNameChange}
     label="Επωνυμία:"
     input={{
       id: 1,
       type: 'text',
       value: clientInfo.business_name
     }} />
     <Input onChange={handleDoyChange}
     label="Δ.Ο.Υ:"
     input={{
       id: 1,
       type: 'text',
       value: clientInfo.doy
     }} />
     </div>)
  }, [clientInfo]);
  return(
    <Fragment>
    {clientExists}
    {completeModal && <NewOrderComplete yesClick={handleCheckYes} noClick={handleCheckNo}/>}
    <div className={classes.searchBlock}>
    <Typeahead
          className={classes.typeaheadSelect}
          id="basic-typeahead-single"
          labelKey="label"
          onChange={setSingleSelections}
          options={options}
          placeholder="Αναζήτηση πελάτη:"
          selected={singleSelections}
        />
    <Button onClick={handleClearClick}>Καθαρισμός</Button>
    
    </div>
    {missingRequired}
    <div className={classes.clientCard}>
    {clientBasicRender}
    </div>
    <div className={classes.clientCard}>
    <div className={classes.timologio}>
    <span>
     <label htmlFor='timologio'>Θέλω τιμολόγιο: </label>
     <input id='timologio' type='checkbox' onClick={handleTimologioClick}></input>
     </span>
     <span className={classes.divider}></span>
     </div>
     </div>
     <div className={classes.clientCard}>
     {timologioToggle && clientInvoiceRender}
     </div>
     <div className={`${classes.ButtonSet}`}>
     <span></span>
     <Link to="../2" state={{deliver: deliver, measure: measure, installPrice: installPrice,  sew: sew, iron: iron, note: note, initPrice: initPrice, totalCost: totalCost, advancePay:advancePay}}>
     <span className={classes.cancelButton}><Button>Πίσω</Button></span>
     </Link>
     <span></span>
     <span className={classes.nextButton}><Button onClick={handleNextButton}>Ολοκλήρωση</Button></span>
     <span></span>
     </div>
     <div>
     <span className={classes.proxeiroButton}><Button onClick={handleProxeiroButton}>Αποθήκευση σαν προσφορά</Button></span>
     </div>
  </Fragment>
  )
}

export default NewOrderClient;
