import React, {useEffect, useState} from 'react';
import Card from '../UI/Card';
import axios from 'axios';
import AppHistoryImport from './AppHistoryImport';
import classes from './AppHistory.module.css';
import { Navigate, Route, Routes, useNavigate,Outlet, Link  } from "react-router-dom";
import {useCookies} from 'react-cookie';

const AppHistory = () =>{
  const navigate = useNavigate();
  const [cookies, setCookies, removeCookies] = useCookies(['user']);

   
useEffect(() => {

  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: 'https://api.orders.e-interior.gr/api/verify',
    headers: { 
      'Accept': 'application/json', 
      'Authorization': 'Bearer '+cookies.Token, 
    },
  };

  axios.request(config)
  .then((response) => {
    console.log(response.data)
    if (response.data.message!=="Success"){
      removeCookies('Name', { path: '/' });
      removeCookies('Token', { path: '/' });
      navigate("/user-login");
    };
  })
  .catch((error) => {
    navigate("/user-login");
    removeCookies('Name', { path: '/' });
    removeCookies('Token', { path: '/' });
  });

}, []);


  return(
    <Card className={classes.AppHistoryCard}>
      <div> <AppHistoryImport/> </div>
    </Card>
  )
}
export default AppHistory;
