import React,{useState,useEffect, Fragment} from 'react';
import Card from '../UI/Card';
import { Navigate, Route, Routes, useNavigate,Outlet, Link  } from "react-router-dom";
import {useCookies} from 'react-cookie';
import classes from './OrderHistory.module.css';
import axios from 'axios';
import Input from '../UI/Input';
import Button from '../UI/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import SingleOrderHistory from './SingleOrderHistory';
import ViewOrderHistory from './ViewOrderHistory';
import ReturnInProgressConfirmation from './ReturnInProgressConfirmation';
import { Typeahead } from 'react-bootstrap-typeahead'; 

const OrdersHistory = () =>{
  const navigate = useNavigate();
  const [cookies, setCookies, removeCookies] = useCookies(['user']);
  const [orders,setOrders] = useState([]);
  const [clients,setClients] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [statusText,setStatusText] = useState("");
  const [editClick,setEditClick] = useState(true);
  const [modalShow,setModalShow] = useState(false);
  const [returnModal, setReturnModal] = useState(false);
  const [currentId,setCurrentId] = useState('');
  const [currentFullName,setCurrentFullName] = useState('');
  const [updatedStatus, setUpdatedStatus] = useState('');
  const [saved, setSaved] = useState('');
  const [currentClients, setCurrentClients] = useState('');
  const [nameSelection, setNameSelection] = useState('0')
  const [statusChange, setStatusChange] = useState(false);
  const [orderRender, setOrderRender] = useState('')
  const [ordersUpdate, setOrdersUpdate] = useState(false);

  const [showProfit, setShowProfit] = useState(false)

  const [singleSelections, setSingleSelections] = useState([]);
  const [options,setOptions]=useState([{id:'',label:''}])


useEffect(() => {
    setIsLoaded(true);

  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: 'https://api.orders.e-interior.gr/api/verify',
    headers: { 
      'Accept': 'application/json', 
      'Authorization': 'Bearer '+cookies.Token, 
    },
  };

  axios.request(config)
  .then((response) => {
    console.log(response.data)
    if (response.data.message!=="Success"){
      removeCookies('Name', { path: '/' });
      removeCookies('Token', { path: '/' });
      navigate("/user-login");
    };
  })
  .catch((error) => {
    removeCookies('Name', { path: '/' });
    removeCookies('Token', { path: '/' });
    navigate("/user-login");
  });

}, []);
  
  useEffect(() => {
    const config = {
      headers: { 'Authorization': 'Bearer '+cookies.Token}
    };
    //get all orders 
    axios.post('https://api.orders.e-interior.gr/api/orders',{
      key: cookies.Token},
      config
    )
    .then(function (response) {
      // console.log(response.data);
      setOrders(response.data);
      // console.log(response.data);

    })
    .catch(function (error) {
      if (error.response.data.message==="Unauthenticated."){
          removeCookies('Name', { path: '/' });
          removeCookies('Token', { path: '/' });
          navigate("/user-login");
        }
      console.log(error);
    });

    //get all clients
    axios.post('https://api.orders.e-interior.gr/api/clients',{
      key: cookies.Token},
      config
    )
    .then(function (response) {
      // console.log(response.data);
      setClients(response.data);
      // console.log(response.data);

    })
    .catch(function (error) {
      console.log(error);
    });



  },[isLoaded,saved, ordersUpdate])

  useEffect(()=>{
    setCurrentClients([...new Set(orders.filter(order =>
      order.status == "completed" || 
      order.status == "canceled"
      ).map(item => item.fullName).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())))]);
  },[orders])

  useEffect(()=>{
    console.log(currentClients)
    let code = ''
    let notes = '';
    let labelStr = '';
    let opt = [];
    let filteredOrders = orders.filter(order => 
      order.status == "completed" || 
      order.status == "canceled")
    for (let order of filteredOrders){
      for (let product of order.products){
        if (product.status!=='deleted'){
        code = product.code
        notes = JSON.parse(product.product_options).notes ? JSON.parse(product.product_options).notes:JSON.parse(product.product_options).freeText?JSON.parse(product.product_options).freeText:'';
        labelStr =code+(notes?' '+notes:'');
        opt.push({
          id: Math.floor(Math.random() * 1000000),
          label: labelStr,
          })
      }
      }
    }
    for (let client of currentClients){
      opt.push({
          id: Math.floor(Math.random() * 1000000),
          label: client,
          })
    }
    opt = opt.reduce((acc, cur) => {
      if (!acc.includes(cur.label)) {
        acc.push(cur.label);
      }
      return acc;
    }, [])
    setOptions(opt)
}, [orders])

  const nameSelectionHandler = (e) =>{
    setNameSelection(e.target.value);
  } 

  const handleModalShow = (e) =>{
    setModalShow(e.modal);
    setCurrentId(e.id);
    setCurrentFullName(e.fullName);
  }
  const handleSaved = (e) =>{
    setSaved(e)
  }
  const onCloseHandler = () =>{
    setModalShow(false);
    setCurrentId('');
    setCurrentFullName('');
  }

  function handleClearClick(){
    setSingleSelections([]);
  }
// setOrdersUpdate(!ordersUpdate);
  const handleReturnedInProgress = (e) => {
    setReturnModal(true);
    setCurrentId(e.id);
  }
  const handleReturnYes = (e) =>{
    let data = new FormData();
    data.append('status', 'submitted');
    data.append('order_id', currentId);

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://api.orders.e-interior.gr/api/order/update',
      headers: { 
        'Accept': 'application/json', 
        'Authorization': 'Bearer '+cookies.Token, 
      },
      data : data
    };

    axios.request(config)
    .then((response) => {
      setReturnModal(false);
      setCurrentId('');
      setOrdersUpdate(!ordersUpdate);
    })
    .catch((error) => {
      console.log(error);
    });
    
  }
  const handleReturnNo = (e) =>{
    setReturnModal(false);
  }
  useEffect(()=>{
    console.log(orders)
    const orderComponents = orders.filter(order =>
      order.status === "completed" || 
      order.status === "canceled"
      ).map(order => {
          if (singleSelections.length===0){

          return(
            <SingleOrderHistory
              saved={handleSaved}
              modalShow={handleModalShow}
              id={order.id}
              key={order.id}
              fullName={order.fullName}
              totalProducts={order.totalProducts}
              totalPrice={order.totalPrice}
              installPrice={order.installPrice}
              status={order.status}
              created_at={order.created_at}
              hasInstallation={order.hasInstallation}
              showProfit={showProfit}
              products={order.products}
              returnedInProgress={handleReturnedInProgress}
            />
          )}else{
            let codeList = [];
            let noteList = []
            for (let product of order.products){
              codeList.push(product.code);
              if (JSON.parse(product.product_options).notes){
                noteList.push(JSON.parse(product.product_options).notes);
              }else if(JSON.parse(product.product_options).freeText){
                noteList.push(JSON.parse(product.product_options).freeText)
              }
            }
            
            if (singleSelections){
              if(codeList.some(code => singleSelections[0].includes(code))||noteList.some(note => singleSelections[0].includes(note))||singleSelections[0]===order.fullName){

                return(
                  <SingleOrderHistory
                    saved={handleSaved}
                    modalShow={handleModalShow}
                    id={order.id}
                    key={order.id}
                    fullName={order.fullName}
                    totalProducts={order.totalProducts}
                    totalPrice={order.totalPrice}
                    installPrice={order.installPrice}
                    status={order.status}
                    created_at={order.created_at}
                    hasInstallation={order.hasInstallation}
                    showProfit={showProfit}
                    products={order.products}
                    returnedInProgress={handleReturnedInProgress}
                  />
                )
              }
            }
          }
      });
        setOrderRender(orderComponents);
  },[orders, nameSelection, statusChange, singleSelections, modalShow, showProfit])

  const handleShowProfitChange = ()=>{
    setShowProfit(!showProfit);
  }
    return(
    <Card className={classes.ordersCard}>
    <h2>Ιστορικό Παραγγελιών</h2>
    {returnModal && <ReturnInProgressConfirmation id={currentId} inView={modalShow} yesClick={handleReturnYes} noClick={handleReturnNo} onClose={onCloseHandler}/>}
    {modalShow && <ViewOrderHistory id={currentId} fullName={currentFullName} onClose={onCloseHandler} returnedInProgress={handleReturnedInProgress}/>}
    {!modalShow &&
    <Fragment>
    
    <div className={classes.searchBlock}>
    <Typeahead
          className={classes.typeaheadSelect}
          id="basic-typeahead-single"
          labelKey="label"
          onChange={setSingleSelections}
          options={options}
          placeholder="Αναζήτηση Πελάτη ή Κωδικού Προϊόντος:"
          selected={singleSelections}
        />
    <Button onClick={handleClearClick}>Καθαρισμός</Button>
    </div>
    <div className={classes.switchContainer}>
      <label className={classes.switch}>
        <input type="checkbox" checked={showProfit} onChange={handleShowProfitChange}/>
        <span className={classes.slider} />
      </label>
      <p>Προβολή Κέρδους</p>
    </div>
    <table>
      <thead>
        <tr>
          <th>#</th>
          <th>Όνομα</th>
          <th>Προϊόντα</th>
          <th>Κόστος</th>
          <th>Κόστος Τοποθέτησης</th>
          <th>Κατάσταση</th>
          <th>Ημερομηνία Δημιουργίας</th>
          {!showProfit&&<th>Ενέργειες</th>}
          {showProfit&&<th>Κέρδος</th>}
        </tr>
      </thead>
      <tbody>
      {orderRender}
      </tbody>
    </table>
    </Fragment>}
    </Card>
    )
}


export default OrdersHistory;