import React,{Fragment,useState,useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faArrowRotateLeft, faStar} from '@fortawesome/free-solid-svg-icons'
import {useCookies} from 'react-cookie';
import classes from './SingleOrderNotSubmitted.module.css'
import axios from 'axios';

const returnArrow = <FontAwesomeIcon icon={faArrowRotateLeft} />
const trash = <FontAwesomeIcon icon={faTrash} />
const star = <FontAwesomeIcon icon={faStar} />

const SingleOrderNotSubmitted = (props) =>{
  const [cookies, setCookies, removeCookies] = useCookies(['user']);
	const [status,setStatus] = useState(props.status);
  const [statusChange, setStatusChange] = useState(props.statusChange);
	const [statusText,setStatusText] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [singleRender, setSingleRender] = useState('');
  const [click, setClick] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [historyView, setHistoryView] = useState("")

  const [totalProfit, setTotalProfit] = useState(0);


  useEffect(() => {
      setIsLoaded(true);
  }, []);
  const viewClick = () =>{
    setModalShow(true);
    props.modalShow({modal:true, id:props.id, status:props.status, scrollPos:window.scrollY});
    setStatus(props.status)
  }
  const returnClick = () =>{
    props.returnClick({id:props.id});
  }
  const deleteClick = () =>{
    props.deleteClick({id:props.id})
  }





  useEffect(()=>{
    let expensesCalc = 0;
    if (props.products){
      for (let product of props.products){
        if (product.status!=='deleted'){
          let wholesale = Number(JSON.parse(product.expenses).wholesale);
          let shipping = Number(JSON.parse(product.expenses).shipping);
          let sewing = Number(JSON.parse(product.expenses).sewing);
          expensesCalc += wholesale+shipping+sewing;
        }
    }
    setTotalProfit(Math.round((props.totalPrice-expensesCalc)*100)/100);
    }
    
  },[props.showProfit,props.products])

useEffect(()=>{
  const statusUpdateDate = new Date(props.status_updated_at).toLocaleString().split(',');

  const meridiem = statusUpdateDate[1].split(' ')[2];
  const statusUpdateTime = statusUpdateDate[1].split(':');
  const formattedStatusDate = `${statusUpdateDate[0]} ${statusUpdateTime[0]}:${statusUpdateTime[1]} ${meridiem!==undefined ? meridiem:''}`
  setSingleRender(
        <tr className={`${classes.orderRow} ${statusText === "Προσφορά" ? classes.proxeiroRow : ''} ${props.showProfit && totalProfit > 0 ? classes.greenRow : ''} ${props.showProfit && totalProfit < 0 ? classes.redRow : ''}`}>
            <td onClick={viewClick}>{props.id}</td>
            <td onClick={viewClick}>{props.vip === 1 &&(<span>{star}</span>)}<p>{props.fullName}</p>
                <p>{props.phone}</p>
            </td>
            <td onClick={viewClick}>{props.totalPrice ? props.totalPrice+'€':'-'}</td>
            <td onClick={viewClick}>{props.created_at.split('T')[0]}</td>
            <td title="Επεξεργασία Παραγγελίας" onClick={returnClick}>{returnArrow}</td>
            <td title="Οριστική Διαγραφή" onClick={deleteClick}>{trash}</td>
            
        </tr>
      );
}, [statusText, props.modalShow])



  return(
    <Fragment>
      {singleRender}
    </Fragment>)
}

export default SingleOrderNotSubmitted;